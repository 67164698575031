import mParticle, { MPConfiguration, ProductActionType, UserIdentities } from "@mparticle/web-sdk";
import isDevelopment from "util/isDevelopment";
import { logError } from "util/Logger";

type CustomEvent = "Login" | "Logout" | "Completed enrollment form";

type IdentityFields = {
  userId: string;
  email: string;
  phone: string;
  loyaltyCardNumber?: string;
};

// initialize the mParticle client SDK
export const initMParticle = () => {
  if (!Env.MParticleKey || !Env.MParticlePlanId || !Env.MParticlePlanVersion) return;

  const mParticleConfig: MPConfiguration = {
    dataPlan: {
      planId: Env.MParticlePlanId,
      planVersion: +Env.MParticlePlanVersion,
    },
    isDevelopmentMode: isDevelopment,
    logLevel: isDevelopment ? "verbose" : "none",
    logger: (() => {
      if (!isDevelopment) return { error: logError };

      return {
        // eslint-disable-next-line no-console
        verbose: console.debug,
        // eslint-disable-next-line no-console
        warning: console.warn,
        // eslint-disable-next-line no-console
        error: console.error,
      };
    })(),
  };
  mParticle.init(Env.MParticleKey, mParticleConfig);
  mParticle.eCommerce.setCurrencyCode("USD");
};

// mParticle user identity mgmt

export const clearMParticleIdentity = () => {
  mParticle.ready(() => {
    mParticle.Identity.logout({});
  });
};

export const modifyMParticleIdentity = (fields: { email?: string; phone?: string }) => {
  const { email, phone } = fields;

  mParticle.ready(() => {
    mParticle.Identity.modify({
      userIdentities: {
        email,
        mobile_number: phone,
      },
    });
  });
};

export const setMParticleIdentity = (fields: IdentityFields) => {
  const { email, loyaltyCardNumber, phone, userId } = fields;

  const userIdentities: UserIdentities = {
    customerid: userId,
    email,
    mobile_number: phone,
  };

  if (loyaltyCardNumber) {
    userIdentities.other = loyaltyCardNumber;
  }

  mParticle.ready(() => {
    mParticle.Identity.login({
      userIdentities,
    });
  });
};

// mParticle event tracking

export const logProductAction = (
  productActionType: ProductActionType,
  data: {
    products: mParticle.Product[];
    orderId?: string;
    storeId?: string;
    storeName?: string;
    revenue?: number;
    isReorder?: boolean;
  }
) => {
  const {
    products,
    orderId: order_id,
    isReorder: reordering_flag,
    revenue,
    storeId: store_id,
    storeName: store_name,
  } = data;

  const customAttributes: mParticle.SDKEventAttrs = {
    reordering_flag: reordering_flag ?? null,
    store_id,
    store_name,
  };

  const transactionAttributes = mParticle.eCommerce.createTransactionAttributes(
    order_id ?? "",
    undefined,
    undefined,
    revenue
  );

  mParticle.ready(() => {
    mParticle.eCommerce.logProductAction(
      productActionType,
      products,
      customAttributes,
      undefined,
      transactionAttributes
    );

    if (isDevelopment) {
      // eslint-disable-next-line no-console
      console.debug("mParticle.eCommerce.logProductAction:", {
        productActionType,
        products,
        customAttributes,
        transactionAttributes,
      });
    }
  });
};

export const logCustomEvent = (eventName: CustomEvent) => {
  mParticle.ready(() => {
    mParticle.logEvent(eventName, mParticle.EventType.Other);

    if (isDevelopment) {
      // eslint-disable-next-line no-console
      console.debug("mParticle.logEvent:", {
        eventName,
        eventType: mParticle.EventType.Other,
      });
    }
  });
};
