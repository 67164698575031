import { useAnalytics } from "analytics";
import { OrderError, UnknownError } from "errors/Errors";
import useFeatureFlag from "hooks/useFeatureFlag";
import useOrderUpsell from "hooks/useOrderUpsell";
import { isGroupOrder, isHostPayGroupOrder, isSplitPayGroupOrder } from "models/groupOrder/GroupOrder";
import { CartItem } from "models/order/CartItem";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAlert } from "stores/alert";
import { useGlobalModal } from "stores/globalModal";
import { useOrder } from "stores/order";
import { useUser } from "stores/user";
import AddParticipantsModal from "ui/components/Cart/AddParticipantsModal";
import LockGroupOrderModal from "ui/components/Cart/LockGroupOrderModal";
import ParticipantStatusModal from "ui/components/Cart/ParticipantStatusModal";
import {
  SortedGroupOrderParticipants,
  sortGroupOrderParticipants,
} from "ui/components/Cart/ParticipantStatusModal/GroupOrderParticipantStatus";
import StartGroupOrderModal from "ui/components/StartGroupOrderModal";
import { CheckoutPagePath, MainPagePath } from "ui/navigation/Pages";
import { logError } from "util/Logger";

const useCartViewModel = ({ isOpen, onClose: closeCart }: { isOpen: boolean; onClose: () => void }) => {
  // stores
  const { addErrorAlert } = useAlert();
  const {
    cancelOrder,
    deleteFromOrder,
    editOrderItemQuantity,
    getCurrentGroupOrderParticipants,
    order,
    orderLedgerItems,
    orderTotal,
  } = useOrder();
  const { addUpsellToOrder, smartUpsellItems, staticUpsellItems } = useOrderUpsell();
  const { isGroupOrderHost, isLoggedIn } = useUser();
  const { closeModal, presentModal } = useGlobalModal();

  // hooks
  const { emitAnalyticsEvent } = useAnalytics();
  const isGrouporderingEnabled = useFeatureFlag("group_ordering");
  const navigate = useNavigate();

  // local state
  const [shouldShowCheckoutLoading, setShouldShowCheckoutLoading] = useState(false);
  const [sortedGroupOrderParticipants, setSortedGroupOrderParticipants] = useState<SortedGroupOrderParticipants>();

  const isHostPayParticipant = isHostPayGroupOrder(order) && !isGroupOrderHost(order.groupOrderDetails);
  const isHostPayHost = isHostPayGroupOrder(order) && isGroupOrderHost(order.groupOrderDetails);
  const isSplitPayHost = isSplitPayGroupOrder(order) && isGroupOrderHost(order.groupOrderDetails);

  const cartBagTitle = isHostPayGroupOrder(order) ? "Group Bag" : "Your Bag";
  const cartItems = order?.items;
  const checkoutBtnLabel = isHostPayParticipant ? "Submit" : "Checkout";
  const isCartEmpty = !order || order.items.length === 0;
  const isOpenRef = useRef(false);
  const ledgerItems = orderLedgerItems;
  const shouldGroupCartItemsByRecipient = isHostPayGroupOrder(order);
  const shouldShowEmptyCheckout = isSplitPayHost;
  const shouldShowParticipantsBanner = isGroupOrder(order) ? isGroupOrderHost(order.groupOrderDetails) : false;
  const shouldShowParticipantStatusButton = shouldShowParticipantsBanner && isSplitPayHost;
  const shouldShowGroupOrderButton = isGrouporderingEnabled && order && !isGroupOrder(order) && isLoggedIn;
  const total = orderTotal;

  const handleAddFoodClick = () => {
    emitAnalyticsEvent("click_link", { text: "Add Food", type: "Cart" });
    closeCart();
    navigate(MainPagePath.menu);
  };

  const handleAddMorePeopleClick = () => {
    presentModal(<AddParticipantsModal onClose={closeModal} open={true} />);
  };

  const handleCartItemQuantityChange = async (item: CartItem, quantity: number) => {
    try {
      // this returns a promise so the quantitystepper knows when loading is finished
      await editOrderItemQuantity(item, quantity);

      // analytics
      if (!order) return;

      const quantityDifference = Math.abs(item.quantity - quantity);

      if (item.quantity > quantity) {
        const analyticsProduct = {
          ...item,
          id: item.product.id,
          quantity: quantityDifference,
        };

        emitAnalyticsEvent("remove_from_cart", {
          products: [analyticsProduct],
          orderType: order.orderType,
          storeName: order.store.name,
        });
        return;
      }

      if (item.quantity < quantity) {
        const analyticsProduct = {
          ...item,
          id: item.product.id,
          quantity: quantityDifference,
        };

        emitAnalyticsEvent("add_to_cart", {
          products: [analyticsProduct],
          order: order,
        });
      }
    } catch (e) {
      addErrorAlert(OrderError.ModifyItem, e);
    }
  };

  const handleCartItemRemove = async (item: CartItem) => {
    try {
      await deleteFromOrder(item);

      if (!order) return;

      emitAnalyticsEvent("remove_from_cart", {
        products: [
          {
            ...item,
            id: item.product.id,
          },
        ],
        orderType: order.orderType,
        storeName: order.store.name,
      });
    } catch (e) {
      addErrorAlert(OrderError.RemoveItem, e);
    }
  };

  const handleCheckoutClick = async () => {
    // if participant of a host-pay group order, skip checkout and go to confirmation screen
    if (isHostPayParticipant) {
      setShouldShowCheckoutLoading(true);

      try {
        // clear the order session
        await cancelOrder();
        closeCart();
        navigate(CheckoutPagePath.hostPayParticipantOrderConfirmation);
      } catch (e) {
        addErrorAlert(UnknownError.Unknown, e);
      }

      setShouldShowCheckoutLoading(false);
      return;
    }

    if (isHostPayHost) {
      presentModal(<LockGroupOrderModal onClose={closeModal} open={true} />);
      return;
    }

    if (isSplitPayHost) {
      try {
        setShouldShowCheckoutLoading(true);

        const groupOrderParticipants = await getCurrentGroupOrderParticipants();
        const sortedGroupOrderParticipants = sortGroupOrderParticipants(groupOrderParticipants);
        setSortedGroupOrderParticipants(sortedGroupOrderParticipants);

        if (sortedGroupOrderParticipants.orderNotSubmittedParticipants.length > 0) {
          presentModal(<ParticipantStatusModal isSubmittingOrder={true} onClose={closeModal} open={true} />);
          return;
        }

        closeCart();
        navigate(CheckoutPagePath.checkout);
      } catch (e) {
        addErrorAlert(UnknownError.Unknown, e);
      } finally {
        setShouldShowCheckoutLoading(false);
      }

      return;
    }

    if (order?.items) {
      const analyticsProducts = order.items.map(({ product, ...rest }) => ({
        ...rest,
        id: product.id,
      }));

      emitAnalyticsEvent("checkout", {
        products: analyticsProducts,
        order: order,
      });
    }

    closeCart();
    navigate(CheckoutPagePath.checkout);
  };

  const handleMakeAGroupOrderClick = () => {
    presentModal(<StartGroupOrderModal onClose={closeModal} open={true} />);
  };

  const handleParticipantStatusClick = () => {
    presentModal(<ParticipantStatusModal isSubmittingOrder={false} onClose={closeModal} open={true} />);
  };

  useEffect(() => {
    async function sendViewCartEvent() {
      if (!order) return;

      try {
        const analyticsProducts = order.items.map(({ product, ...rest }) => ({
          ...rest,
          id: product.id,
        }));

        emitAnalyticsEvent("view_cart", {
          products: analyticsProducts,
          orderType: order.orderType,
          storeName: order.store.name,
        });
      } catch (e) {
        logError(e);
      }
    }

    // only send analytics event if the cart went from closed to open
    if (isOpen === true && isOpenRef.current === false) {
      sendViewCartEvent();
    }

    isOpenRef.current = isOpen;
  }, [order, isOpen, emitAnalyticsEvent]);

  return {
    addUpsellToOrder,
    cartBagTitle,
    cartItems,
    checkoutBtnLabel,
    handleAddFoodClick,
    handleAddMorePeopleClick,
    handleCartItemQuantityChange,
    handleCartItemRemove,
    handleCheckoutClick,
    handleMakeAGroupOrderClick,
    handleParticipantStatusClick,
    isCartEmpty,
    ledgerItems,
    shouldGroupCartItemsByRecipient,
    shouldShowEmptyCheckout,
    shouldShowParticipantsBanner,
    shouldShowParticipantStatusButton,
    shouldShowCheckoutLoading,
    shouldShowGroupOrderButton,
    sortedGroupOrderParticipants,
    smartUpsellItems,
    staticUpsellItems,
    total,
  };
};

export default useCartViewModel;
