import {
  getLocallySeenAchievedBadges,
  getLocallySeenAvailableBadges,
  setBadgesAsLocallySeen,
} from "hooks/useBadgeManager.helpers";
import useFeatureFlag from "hooks/useFeatureFlag";
import AchievementBadge from "models/rewards/AchievementBadge";
import { useCallback, useMemo } from "react";
import { useUser } from "stores/user";

const useBadgeManager = () => {
  const isAchievementBadgesEnabled = useFeatureFlag("achievement_badges");
  const { rewards, updateAchievementBadgesAsSeen } = useUser();

  const unseenBadges = useMemo(() => {
    if (!isAchievementBadgesEnabled || !rewards?.badges?.length) return;

    const locallySeenAchievedBadges = getLocallySeenAchievedBadges();
    const locallySeenAvailableBadges = getLocallySeenAvailableBadges();

    const unseenBadges = rewards.badges.filter((badge) => {
      switch (badge.status) {
        case "ACHIEVED":
          return !locallySeenAchievedBadges.includes(badge.id);
        case "AVAILABLE":
          return !locallySeenAvailableBadges.includes(badge.id);
        default:
          return false;
      }
    });

    return unseenBadges;
  }, [isAchievementBadgesEnabled, rewards?.badges]);

  const markBadgesAsSeen = useCallback(
    (badges: AchievementBadge[]) => {
      // mark badges as seen in local browser storage
      setBadgesAsLocallySeen(badges);

      // mark badges as seen via the rewards API (Paytronix)
      updateAchievementBadgesAsSeen(badges);
    },
    [updateAchievementBadgesAsSeen]
  );

  return {
    unseenBadges,
    markBadgesAsSeen,
  };
};

export type BadgeModalManager = ReturnType<typeof useBadgeManager>;
export default useBadgeManager;
