import { useAnalytics } from "analytics";
import useGroupOrderHost from "hooks/useGroupOrderHost";
import { isPickupType } from "models/order/OrderType";
import OrderAheadDateTimes from "models/store/OrderAheadDateTimes";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useOrder } from "stores/order";
import { useStore } from "stores/store";
import { useUser } from "stores/user";
import { fieldOptions, StartGroupOrderSchema } from "ui/components/StartGroupOrderModal/StartGroupOrderModal.data";
import { MainPagePath } from "ui/navigation/Pages";
import { logError } from "util/Logger";

export type StartGroupOrderModalViewModelProps = {
  onClose: () => void;
  open: boolean;
};

const useStartGroupOrderModalViewModel = ({ onClose, open }: StartGroupOrderModalViewModelProps) => {
  const { emitAnalyticsEvent } = useAnalytics();
  const { createGroupOrder, order } = useOrder();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { getOrderAheadTimes } = useStore();
  const { getSavedGroupOrderParticipants, isLoggedIn } = useUser();
  const { groupOrderHostEmail } = useGroupOrderHost();
  const [savedParticipants, setSavedParticipants] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [orderAheadDateTimes, setOrderAheadDateTimes] = useState<OrderAheadDateTimes[]>([]);
  const [shouldDisableOrderTimePicker, setShouldDisableOrderTimePicker] = useState(false);
  const isPickup = isPickupType(order?.orderType);
  const redirectPathAfterOrderStart = searchParams.get("next") ?? MainPagePath.menu;

  useEffect(() => {
    if (!open || savedParticipants.length || !isLoggedIn) return;

    const loadSavedParticipants = async () => {
      try {
        const savedParticipants = await getSavedGroupOrderParticipants();
        setSavedParticipants(savedParticipants);
      } catch (error) {
        setSavedParticipants([]);
      }
    };

    loadSavedParticipants();
  }, [getSavedGroupOrderParticipants, isLoggedIn, open, savedParticipants.length]);

  const {
    control,
    formState: { errors: fieldErrors },
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = useForm<StartGroupOrderSchema>({
    defaultValues: {
      participantEmails: [],
      timeWanted: "",
    },
  });

  const [groupOrderPaymentType, orderType, timeWanted] = watch(["groupOrderPaymentType", "orderType", "timeWanted"]);

  const onSubmit = handleSubmit(async (fields) => {
    setIsSubmitting(true);

    try {
      await createGroupOrder(fields);

      emitAnalyticsEvent("start_group_order", {
        participantCount: fields.participantEmails.length,
        timeWanted: fields.timeWanted,
        type: fields.groupOrderPaymentType,
      });

      onClose();
      navigate(redirectPathAfterOrderStart);
    } catch (e) {
      logError(e);
    }

    setIsSubmitting(false);
  });

  // set orderType from order as default value for pickup type selector
  useEffect(() => {
    if (!order?.orderType) return;
    setValue("orderType", order.orderType);
  }, [order?.orderType, setValue]);

  // load order ahead dates/times on mount & any time orderType is changed
  useEffect(() => {
    if (!orderType || !order?.store.id) return;

    const updateOrderAheadTimes = async () => {
      try {
        setShouldDisableOrderTimePicker(true);
        const orderAheadDateTimes = await getOrderAheadTimes(order.store.id, orderType);

        const filteredOrderAheadDateTimes = orderAheadDateTimes.map(({ text, timeSlots }) => ({
          text,
          timeSlots: timeSlots.filter(({ text }) => text.toUpperCase() !== "ASAP"),
        }));

        setOrderAheadDateTimes(filteredOrderAheadDateTimes);
      } catch (e) {
        setOrderAheadDateTimes([]);
        logError(e);
      } finally {
        setShouldDisableOrderTimePicker(false);
      }
    };

    updateOrderAheadTimes();
  }, [getOrderAheadTimes, order?.store.id, orderType]);

  useEffect(() => {
    if (timeWanted || orderAheadDateTimes.length === 0) return;

    setValue("timeWanted", orderAheadDateTimes[0]?.timeSlots[0]?.value);
  }, [orderAheadDateTimes, setValue, timeWanted]);

  useEffect(() => {
    return function tearDown() {
      reset();
    };
  }, [reset]);

  return {
    control,
    fieldErrors,
    fieldOptions,
    groupOrderPaymentType,
    groupOrderHostEmail,
    isPickup,
    isSubmitting,
    onSubmit,
    onClose,
    orderAheadDateTimes,
    orderType: orderType ?? order?.orderType,
    register,
    savedParticipants,
    shouldDisableOrderTimePicker,
    store: order?.store,
    timeWanted,
  };
};

export default useStartGroupOrderModalViewModel;
