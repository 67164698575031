import { Thumbnail, ThumbnailType } from "models/Thumbnail";
import { Link } from "ui/app/Footer/FooterLinkColumns";
import { IconType } from "ui/commerceUI/foundations/icons/Icon";

const mapIconForThumbnailType = (type: ThumbnailType): IconType =>
  ({
    [ThumbnailType.facebook]: IconType.Facebook,
    [ThumbnailType.instagram]: IconType.Instagram,
    [ThumbnailType.twitter]: IconType.Twitter,
    [ThumbnailType.youtube]: IconType.YouTube,
  }[type]);

const mapThumbnailsToFooterLink = (thumbnails: Thumbnail[]): Link[] =>
  (thumbnails ?? []).map(
    (t) =>
      ({
        url: t.url,
        icon: mapIconForThumbnailType(t.type),
        label: t.text,
      } as Link)
  );

const useFooterViewModel = (currentDate = new Date()) => ({
  copyright: `TM & © ${currentDate.getFullYear()} Jimmy John's Franchisor SPV, LLC All Rights Reserved.`,
  socialLinks: mapThumbnailsToFooterLink([
    { type: ThumbnailType.twitter, text: "Twitter", url: "https://twitter.com/jimmyjohns" },
    { type: ThumbnailType.facebook, text: "Facebook", url: "https://www.facebook.com/jimmyjohns/" },
    { type: ThumbnailType.instagram, text: "Instagram", url: "https://www.instagram.com/jimmyjohns/" },
    { type: ThumbnailType.youtube, text: "YouTube", url: "https://www.youtube.com/user/jimmyjohns" },
  ]),
});

export default useFooterViewModel;
