import { useAnalytics } from "analytics";
import { useNavigate } from "react-router-dom";
import { useCart } from "stores/cart";
import { useOrder } from "stores/order";
import { CheckoutPagePath } from "ui/navigation/Pages";

export type LockGroupOrderModalViewModelProps = {
  onClose: () => void;
};

const useLockGroupOrderModalViewModel = ({ onClose }: LockGroupOrderModalViewModelProps) => {
  const { emitAnalyticsEvent } = useAnalytics();
  const { hideCart } = useCart();
  const navigate = useNavigate();
  const { order } = useOrder();

  const checkout = async () => {
    if (order?.items) {
      const analyticsProducts = order.items.map(({ product, ...rest }) => ({ ...rest, id: product.id }));
      emitAnalyticsEvent("checkout", {
        products: analyticsProducts,
        order,
      });
    }

    hideCart();
    navigate(CheckoutPagePath.checkout);
    onClose();
  };

  return {
    onClose,
    checkout,
  };
};

export default useLockGroupOrderModalViewModel;
