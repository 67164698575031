import { Box, Button, Stack } from "@mui/material";
import styles from "ui/components/Cart/ParticipantsBanner/ParticipantsBanner.styles";
import Icon from "ui/components/Icon";

type ParticipantsBannerProps = {
  handleAddMorePeopleClick: () => void;
  handleParticipantStatusClick: () => void;
  shouldShowParticipantStatusButton: boolean;
};

const ParticipantsBanner = ({
  handleAddMorePeopleClick,
  handleParticipantStatusClick,
  shouldShowParticipantStatusButton,
}: ParticipantsBannerProps) => (
  <Stack sx={styles.participantsBanner}>
    <Box sx={styles.addParticipantsButton}>
      <Button onClick={handleAddMorePeopleClick} variant="text">
        Add More People
      </Button>
      <Icon icon="groupOrder" />
    </Box>
    {shouldShowParticipantStatusButton && (
      <Button onClick={handleParticipantStatusClick} variant="text">
        Participant Status
      </Button>
    )}
  </Stack>
);

export default ParticipantsBanner;
