import { useAnalytics } from "analytics";
import { createContext, ReactNode, useEffect, useMemo, useState } from "react";
import { useAlert } from "stores/alert";
import { useMenu } from "stores/menu";
import OctocartStoreManageable from "stores/OctocartStoreManageable";
import { useOrder } from "stores/order";
import useStore from "stores/store/useStore";
import { StoreManager } from "stores/StoreManager";
import { useUser } from "stores/user";

type StoreManagerContextValue = {
  storeManager: OctocartStoreManageable;
  isLoading: boolean;
};

export const StoreManagerContext = createContext<StoreManagerContextValue | null>(null);

type StoreProviderProps = {
  children: ReactNode;
};

const StoreManagerProvider = ({ children }: StoreProviderProps) => {
  const alertStore = useAlert();
  const orderStore = useOrder();
  const storeStore = useStore();
  const menuStore = useMenu();
  const userStore = useUser();
  const { emitAnalyticsEvent } = useAnalytics();

  const storeManager: OctocartStoreManageable = useMemo(
    () => new StoreManager(alertStore, orderStore, storeStore, menuStore, userStore, emitAnalyticsEvent),
    [alertStore, orderStore, storeStore, menuStore, userStore, emitAnalyticsEvent]
  );

  const [isMounted, setIsMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // bootstrap app data stores
  useEffect(() => {
    // only call StoreManager.start() once, on mount. Prevents duplicate API requests due to re-renders.
    if (isMounted) return;
    setIsMounted(true);

    try {
      storeManager.start();
    } finally {
      setIsLoading(false);
    }
  }, [isMounted, storeManager]);

  return <StoreManagerContext.Provider value={{ storeManager, isLoading }}>{children}</StoreManagerContext.Provider>;
};

export default StoreManagerProvider;
