import AnalyticsEventRegistry, { AnalyticsProduct } from "analytics/AnalyticsEventRegistry";
import { Ecommerce, EcommerceEvent, EcommerceItem } from "analytics/vendors/GoogleAnalytics/GoogleAnalytics.types";
import {
  calculateProductPrice,
  calculateTotalCostOfItems,
  eccomerceItemFromCategory,
  errorTypeToAnalyticsErrorCategory,
  getComboModifiers,
  getSelectedSizeOption,
} from "analytics/vendors/GoogleAnalytics/GoogleAnalytics.utils";
import { DateTime } from "luxon";
import { isGroupOrder } from "models/groupOrder";
import { PaymentType } from "models/payment/PaymentType";
import { useCallback, useEffect, useRef } from "react";
import { useMenu } from "stores/menu";
import { ProductModifierManager } from "ui/screens/Product/ProductModifiers/ProductModifierManager";
import EventEmitter from "util/EventEmitter";
import { currencyStringToNumber } from "util/StringUtils";
import { v4 as uuidv4 } from "uuid";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const dataLayer: any[];

const useGoogleAnalyticsEventTracking = (emitter: EventEmitter<AnalyticsEventRegistry>) => {
  const eventRegistrationRef = useRef(false);
  const menuStore = useMenu();

  /**
   * Helper Functions
   */

  const eccomerceItemFromAnalyticsProduct = useCallback(
    async ({
      analyticsProduct,
      index,
      listName,
    }: {
      analyticsProduct: AnalyticsProduct;
      index?: number;
      listName?: string;
    }) => {
      const menuProduct = menuStore.products?.find((menuProduct) => menuProduct.id === analyticsProduct.id);

      if (!menuProduct) return;

      const modifiers = await (async () => {
        if (!analyticsProduct.options?.length) return;

        const modifierCategories = await menuStore.getModifierCategoriesForProduct(analyticsProduct.id);
        const optionIds = analyticsProduct.options.map((o) => o.id);
        const modifiers = modifierCategories.map((c) => c.modifiers).reduce((a, b) => a.concat(b), []);

        return ProductModifierManager.applyModifierOptions(modifiers, optionIds);
      })();

      const comboModifiers = modifiers ? getComboModifiers(modifiers) : undefined;
      const sizeSelectedOption = modifiers ? getSelectedSizeOption(modifiers) : undefined;
      const category = menuProduct.categories[0];
      const is_catering = category.menuType === "catering" ? "Yes" : "No";
      const item_category = category.name;
      const item_category2 = comboModifiers?.length ? "Combo" : undefined;
      const item_list_name = listName;
      const price = analyticsProduct.price
        ? currencyStringToNumber(analyticsProduct.price)
        : calculateProductPrice(menuProduct, modifiers);

      return {
        index,
        is_catering,
        item_id: menuProduct.id,
        item_name: menuProduct.name,
        item_category,
        item_category2,
        item_list_name,
        item_variant: sizeSelectedOption?.text,
        price,
        quantity: analyticsProduct.quantity,
      };
    },
    [menuStore]
  );

  const eccomerceItemsFromAnalyticsProducts = useCallback(
    async ({ analyticsProducts, listName }: { analyticsProducts: AnalyticsProduct[]; listName?: string }) => {
      const results = await Promise.all(
        analyticsProducts.map(async (analyticsProduct, index) => {
          return await eccomerceItemFromAnalyticsProduct({ analyticsProduct, index, listName });
        })
      );

      return results.filter(Boolean) as EcommerceItem[];
    },
    [eccomerceItemFromAnalyticsProduct]
  );

  const sendEcommerceEvent = <Event extends EcommerceEvent>(event: Event, ecommerce: Ecommerce<Event>) => {
    dataLayer.push({ ecommerce: null });
    dataLayer.push({ event, ecommerce });
  };

  // Event Registration
  useEffect(() => {
    // make sure that we only register these events once
    if (eventRegistrationRef.current) return;
    eventRegistrationRef.current = true;

    /** Ecommerce Events */

    /** @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events#add_payment_info */
    emitter.on("add_payment_info", async ({ products, payments, storeName }) => {
      const items = await eccomerceItemsFromAnalyticsProducts({ analyticsProducts: products });

      const paymentMethods = payments.map((payment) => {
        switch (payment.type) {
          case PaymentType.savedPayment:
            return "wallet";
          case PaymentType.digitalWallet:
            return "digital";
          default:
            return "new";
        }
      });

      const paymentTypes = payments.map((payment) => {
        if (payment.type === PaymentType.savedPayment) return PaymentType.credit;
        return payment.type;
      });

      sendEcommerceEvent("add_payment_info", {
        currency: "USD",
        items,
        location_id: storeName,
        payment_method: paymentMethods.join(),
        payment_type: paymentTypes.join(),
        value: calculateTotalCostOfItems(items),
      });
    });

    /** @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events#view_cart */
    emitter.on("add_shipping_info", async ({ products, orderType, storeName }) => {
      const items = await eccomerceItemsFromAnalyticsProducts({ analyticsProducts: products });

      const ecommerce = {
        currency: "USD",
        items,
        location_id: storeName,
        shipping_tier: orderType,
        value: calculateTotalCostOfItems(items),
      };

      sendEcommerceEvent("add_shipping_info", ecommerce);
    });

    /** @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events#add_to_cart */
    emitter.on("add_to_cart", async ({ products, order, listName }) => {
      const items = await eccomerceItemsFromAnalyticsProducts({ analyticsProducts: products });

      const ecommerce = {
        currency: "USD",
        handoff_type: order.orderType,
        item_list_name: listName,
        items,
        location_id: order.store.name,
        value: calculateTotalCostOfItems(items),
      };

      sendEcommerceEvent("add_to_cart", ecommerce);
    });

    /** @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events#view_cart */
    emitter.on("checkout", async ({ products, order }) => {
      const items = await eccomerceItemsFromAnalyticsProducts({ analyticsProducts: products });

      sendEcommerceEvent("begin_checkout", {
        currency: "USD",
        handoff_type: order.orderType,
        items,
        location_id: order.store.name,
        value: calculateTotalCostOfItems(items),
      });
    });

    /** @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events#purchase */
    emitter.on("purchase", async ({ order }) => {
      const analyticsProducts = order.items.map(({ product, ...rest }) => ({ ...rest, id: product.id }));
      const items = await eccomerceItemsFromAnalyticsProducts({ analyticsProducts });

      const couponStr = order.appliedRewards?.[0]?.description ?? "";
      const coupon = currencyStringToNumber(couponStr);
      const deliveryFeeStr = order.totals.find(({ id }) => id === "delivery_fee")?.value ?? "";
      const deliveryFee = currencyStringToNumber(deliveryFeeStr);
      const taxStr = order.totals.find(({ id }) => id === "tax")?.value ?? "";
      const tax = currencyStringToNumber(taxStr);
      const totalStr = order.totals.find(({ id }) => id === "total")?.value ?? "";
      const total = currencyStringToNumber(totalStr);

      const ecommerce = {
        coupon,
        currency: "USD",
        group_order: isGroupOrder(order) ? true : undefined,
        handoff_type: order.orderType,
        items,
        location_id: order.store.name,
        shipping: deliveryFee,
        tax: tax ?? 0,
        value: total ?? 0,
      };

      sendEcommerceEvent("purchase", ecommerce);
    });

    /** @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events#remove_from_cart */
    emitter.on("remove_from_cart", async ({ products, orderType, storeName }) => {
      const items = await eccomerceItemsFromAnalyticsProducts({ analyticsProducts: products });

      sendEcommerceEvent("remove_from_cart", {
        currency: "USD",
        handoff_type: orderType,
        items,
        location_id: storeName,
        value: calculateTotalCostOfItems(items),
      });
    });

    /** @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#select_item */
    emitter.on("select_category", ({ category, index, listName }) => {
      sendEcommerceEvent("select_item", {
        item_list_name: listName,
        items: [eccomerceItemFromCategory({ category, index, listName })],
      });
    });

    /** @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#select_item */
    emitter.on("select_product", async ({ index, listName, product: analyticsProduct }) => {
      const item = await eccomerceItemFromAnalyticsProduct({ index, listName, analyticsProduct });

      if (!item) return;

      sendEcommerceEvent("select_item", {
        item_list_name: listName,
        items: [item],
      });
    });

    /** @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events#view_cart */
    emitter.on("view_cart", async ({ products, orderType, storeName }) => {
      const items = await eccomerceItemsFromAnalyticsProducts({ analyticsProducts: products });

      sendEcommerceEvent("view_cart", {
        currency: "USD",
        handoff_type: orderType,
        items,
        location_id: storeName,
        value: calculateTotalCostOfItems(items),
      });
    });

    /** @see https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag#select_an_item_from_a_list */
    emitter.on("view_category_list", ({ listName, categories }) => {
      const items = categories.map((category, index) => eccomerceItemFromCategory({ category, index, listName }));

      sendEcommerceEvent("view_item_list", {
        item_list_name: listName,
        items,
      });
    });

    /** @see https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag#view_item_details */
    emitter.on("view_product", async ({ product, order }) => {
      const item = await eccomerceItemFromAnalyticsProduct({ analyticsProduct: product });

      if (!item) return;

      sendEcommerceEvent("view_item", {
        currency: "USD",
        handoff_type: order?.orderType,
        items: [item],
        location_id: order?.store.name,
        value: item.price ?? 0,
      });
    });

    /** @see https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag#select_an_item_from_a_list */
    emitter.on("view_product_list", async ({ listName, products }) => {
      const items = await eccomerceItemsFromAnalyticsProducts({ analyticsProducts: products, listName });

      const ecommerce = {
        currency: "USD",
        item_list_name: listName,
        items,
      };

      dataLayer.push({ ecommerce: null });
      dataLayer.push({ event: "view_item_list", ecommerce });
    });

    /** Custom Account Events */

    emitter.on("add_fave_to_cart", () => {
      dataLayer.push({ event: "account", account_engagement: "Add Fav To Order" });
    });

    emitter.on("check_in", () => {
      dataLayer.push({ event: "account", account_engagement: "Check InOrder" });
    });

    emitter.on("delete_account", ({ step }) => {
      dataLayer.push({ event: "account", account_engagement: step });
    });

    emitter.on("create_account", ({ step }) => {
      dataLayer.push({ event: "account", account_engagement: "Create Account", account_creation_step: step });
    });

    emitter.on("remove_fave", () => {
      dataLayer.push({ event: "account", account_engagement: "RemoveFav" });
    });

    emitter.on("sign_in", ({ step }) => {
      dataLayer.push({ event: "account", account_engagement: "Sign In", account_sign_in_step: step });
    });

    emitter.on("update_password", ({ step }) => {
      dataLayer.push({ event: "account", account_engagement: step });
    });

    emitter.on("update_profile", ({ step }) => {
      dataLayer.push({ event: "account", account_engagement: step });
    });

    /** Custom Cross-Sell Events */

    emitter.on("add_cross_sell_to_cart", ({ placement, productName }) => {
      dataLayer.push({
        event: "cross_sell",
        cross_sell_engagement: "Add To Bag",
        cross_sell_placement: placement,
        cross_sell_item_name: productName,
      });
    });

    emitter.on("scroll_cross_sell", () => {
      dataLayer.push({
        event: "cross_sell",
        cross_sell_engagement: "Scroll",
      });
    });

    emitter.on("view_cross_sell", ({ productName }) => {
      dataLayer.push({
        event: "cross_sell",
        cross_sell_engagement: "Impression",
        cross_sell_item_name: productName,
      });
    });

    /** Custom Modal Events */

    emitter.on("click_modal_cta", ({ cta, modal }) => {
      dataLayer.push({
        event: "modal",
        link_text: cta,
        modal_interaction: "CTA Click",
        modal_name: modal,
      });
    });

    emitter.on("view_modal", ({ modal, type }) => {
      dataLayer.push({
        event: "modal",
        modal_interaction: "Impression",
        modal_name: modal,
        modal_type: type,
      });
    });

    /** Custom Location Events */

    emitter.on("click_location_cta", ({ cta }) => {
      dataLayer.push({ event: "location", location_engagement: "Location CTA", location_cta: cta });
    });

    emitter.on("find_location", ({ query, filters }) => {
      dataLayer.push({
        event: "location",
        location_engagement: "Location Search",
        location_search_term: query,
        location_search_filter: filters,
      });
    });

    emitter.on("select_location", ({ address, storeName }) => {
      dataLayer.push({
        event: "location",
        location_engagement: "Location Selection",
        location_id: storeName,
        location_store_address: address,
      });
    });

    /** Custom Banner Events */

    emitter.on("click_banner_cta", ({ banner, cta }) => {
      dataLayer.push({
        event: "banner",
        banner_interaction: "CTA Click",
        banner_name: banner,
        link_text: cta,
      });
    });

    emitter.on("view_banner", ({ banner, type }) => {
      dataLayer.push({
        event: "banner",
        banner_interaction: "Impression",
        banner_name: banner,
        banner_type: type,
      });
    });

    /** Custom Gift Card Events */

    emitter.on("click_gift_card_cta", ({ cta, gift_card }) => {
      dataLayer.push({
        event: "gift_card",
        gift_card_interaction: "CTA Click",
        gift_card_name: gift_card,
        link_text: cta,
      });
    });

    emitter.on("view_gift_card", ({ gift_card, type }) => {
      dataLayer.push({
        event: "gift_card",
        gift_card_interaction: "Impression",
        gift_card_name: gift_card,
        gift_card_type: type,
      });
    });

    /** Custom Group Ordering Events */

    emitter.on("start_group_order", ({ participantCount, timeWanted, type }) => {
      const group_order_pay = type === "host" ? "Host" : "Invitee";

      // "Today" or number of days ahead as string
      const group_order_pickup_time = (() => {
        const now = DateTime.now();
        const today = now.toLocaleString(DateTime.DATE_SHORT);
        const dateTimeWanted = DateTime.fromISO(timeWanted);
        const dateWanted = dateTimeWanted.toLocaleString(DateTime.DATE_SHORT);

        if (dateWanted === today) return "Today";

        const numberOfDaysAhead = now.until(dateTimeWanted).count("days").toString();

        return numberOfDaysAhead;
      })();

      dataLayer.push({
        event: "group_order",
        group_order_pay,
        group_order_pickup_time,
        group_order_invitees: participantCount,
      });
    });

    /** Custom Achievement Badge Events */

    emitter.on("click_badge", ({ badge }) => {
      dataLayer.push({
        event: "badge",
        badge_interaction: "Badge Selection",
        badge_placement: "Rewards",
        badge_name: badge.name,
        badge_type: badge.status === "ACHIEVED" || badge.status === "ACHIEVED_AND_SEEN" ? "Achieved" : "Available",
      });
    });

    emitter.on("click_badge_modal_cta", ({ badge, cta }) => {
      dataLayer.push({
        event: "badge",
        badge_interaction: "Impression CTA",
        badge_name: badge.name,
        badge_placement: "Modal",
        badge_type: badge.status === "ACHIEVED" || badge.status === "ACHIEVED_AND_SEEN" ? "Achieved" : "Available",
        link_text: cta,
      });
    });

    emitter.on("view_badge_modal", ({ badge }) => {
      dataLayer.push({
        event: "badge",
        badge_placement: "Modal",
        badge_interaction: "Impression",
        badge_name: badge.name,
        badge_type: badge.status === "ACHIEVED" || badge.status === "ACHIEVED_AND_SEEN" ? "Achieved" : "Available",
      });
    });

    /** Custom Order Events */

    emitter.on("cancel_order", ({ orderId }) => {
      dataLayer.push({
        event: "order",
        order_interaction: "Cancel Order",
        order_id: orderId,
      });
    });

    emitter.on("repeat_order", ({ orderId, page }) => {
      dataLayer.push({
        event: "order",
        order_interaction: "Reorder",
        order_freeform_text: `Reorder From ${page}`,
        order_id: orderId,
      });
    });

    emitter.on("view_past_order", ({ orderId }) => {
      dataLayer.push({
        event: "order",
        order_interaction: "View Previous Order",
        order_id: orderId,
      });
    });

    /** Misc Custom Events */

    emitter.on("click_link", ({ text, type }) => {
      dataLayer.push({ event: "link_click", link_text: text, link_type: type });
    });

    emitter.on("modify_item", ({ engagement, productName, selectedOption, cost }) => {
      dataLayer.push({
        event: "modify",
        modify_engagement: engagement,
        modify_value: selectedOption,
        modify_item_name: productName,
        price: cost ?? 0,
      });
    });

    emitter.on("read_faq", ({ section, question }) => {
      dataLayer.push({ event: "faqs", faq_engagement: "Read", faq_group: section, faq_title: question });
    });

    emitter.on("select_reward", ({ engagement, placement, reward }) => {
      dataLayer.push({
        event: "rewards",
        rewards_engagement: engagement,
        reward_placement: placement,
        offer_name: reward,
      });
    });

    /** @see https://developers.google.com/analytics/devguides/collection/ga4/user-id */
    emitter.on("set_user", ({ userId }) => {
      dataLayer.push({
        event: "userId",
        userID: userId ?? uuidv4(),
        isLoggedIn: !!userId,
        userAgent: window.navigator.userAgent,
      });
    });

    emitter.on("view_error", ({ displayError, type, errorMessage }) => {
      dataLayer.push({
        event: "errors",
        error_category: errorTypeToAnalyticsErrorCategory(type),
        error_description: displayError,
        error_description_vendor: errorMessage,
      });
    });
  }, [eccomerceItemFromAnalyticsProduct, eccomerceItemsFromAnalyticsProducts, emitter, menuStore.products]);
};

export default useGoogleAnalyticsEventTracking;
