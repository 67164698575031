import { Box, Divider, Drawer, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { observer } from "mobx-react-lite";
import StickyAlertsView from "ui/app/StickyAlertsView";
import styles from "ui/components/Cart/Cart.styles";
import useViewModel from "ui/components/Cart/CartViewModel";
import CartWithItems from "ui/components/Cart/CartWithItems";
import EmptyCart from "ui/components/Cart/EmptyCart";
import ParticipantsBanner from "ui/components/Cart/ParticipantsBanner";
import Icon from "ui/components/Icon/Icon";

type CartProps = {
  isOpen: boolean;
  onClose: () => void;
};

const Cart = observer(({ isOpen, onClose }: CartProps) => {
  const { cartBagTitle, isCartEmpty, shouldShowParticipantsBanner, ...viewModel } = useViewModel({ isOpen, onClose });

  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <Drawer anchor="right" onClose={onClose} open={isOpen} sx={styles.drawer}>
        <Box sx={styles.header}>
          <Typography sx={styles.cartDrawerTitle} variant="h4">
            {cartBagTitle}
          </Typography>
          <IconButton aria-label="close the cart" onClick={onClose} sx={styles.closeIcon}>
            <Icon icon="closeDark" size="medium" />
          </IconButton>
        </Box>
        {!mdUp && <StickyAlertsView />}
        {shouldShowParticipantsBanner ? (
          <ParticipantsBanner {...viewModel} />
        ) : (
          <Divider orientation="horizontal" variant="middle" aria-hidden="true" sx={styles.divider} />
        )}
        {isCartEmpty ? <EmptyCart {...viewModel} /> : <CartWithItems {...viewModel} />}
      </Drawer>
    </>
  );
});

export default Cart;
