import { clearMParticleIdentity } from "analytics/vendors/mParticle/mParticle.utils";
import TokenManageable from "dataTransformers/TokenManageable";
import { OctocartError } from "errors/OctocartError";
import { action, makeAutoObservable, makeObservable } from "mobx";
import { User } from "models/User";
import { WritableLoadableObservableState } from "stores/LoadableObservableState";
import LoadableObservableStatus from "stores/LoadableObservableStatus";
import LocalStorage from "util/LocalStorage";
import { setSessionEmail } from "util/SessionStorage";

class TokenManager implements TokenManageable {
  readonly webToken: WritableLoadableObservableState<string> = {
    object: undefined,
    error: undefined,
    status: LoadableObservableStatus.Idle,
  };

  readonly userState: WritableLoadableObservableState<User> = {
    object: undefined,
    error: undefined,
    status: LoadableObservableStatus.Idle,
  };

  protected readonly localStorage: LocalStorage<string>;

  constructor(localStorage = new LocalStorage<string>("token")) {
    makeAutoObservable(this.webToken);
    makeAutoObservable(this.userState);
    makeObservable(this);

    this.localStorage = localStorage;
  }

  @action setToken(status: LoadableObservableStatus, webToken: string | undefined): void {
    this.webToken.object = webToken;
    this.webToken.status = status;
  }

  public hasUserToken() {
    return !!this.localStorage.get();
  }

  @action updateUserState(user: User | undefined, error?: OctocartError): void {
    this.userState.object = user;
    this.userState.error = error;
    this.userState.status = error ? LoadableObservableStatus.Error : LoadableObservableStatus.Idle;

    setSessionEmail(user?.email);
  }

  @action clearUserState(): void {
    this.userState.object = undefined;
    this.userState.error = undefined;
    this.userState.status = LoadableObservableStatus.Idle;
    this.localStorage.clear();
    clearMParticleIdentity();
  }
}

export default TokenManager;
