import { useDecision } from "@optimizely/react-sdk";

/** Active feature flags */
type FeatureFlags = "achievement_badges" | "group_ordering" | "test_flag" | "third_party_apple_pay";

/** Convenience function for checking if a given feature flag is enabled for the current user. */
const useFeatureFlag = (flagKey: FeatureFlags) => {
  const [decision] = useDecision(flagKey);
  return decision.enabled;
};

export default useFeatureFlag;
