import { Box, Divider, Link } from "@mui/material";
import ccpaOptOutIcon from "assets/icons/ccpa-opt-out.svg";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import styles from "ui/app/Footer/SecondaryLinks/SecondaryLinks.styles";
import { MainPagePath } from "ui/navigation/Pages";

const SecondaryLinks = () => {
  const onClickCookieSettings = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    if (ketch) {
      ketch("showPreferences");
    }
  };

  return (
    <Box sx={styles.secondaryLinks}>
      <SecondaryLink to={MainPagePath.termsAndConditions}>Terms & Conditions</SecondaryLink>
      <SecondaryLinkDivider />
      <SecondaryLink to={MainPagePath.adsCookiePolicy}>Ad & Cookie Policy</SecondaryLink>
      <SecondaryLinkDivider />
      <SecondaryLink to={MainPagePath.privacyPolicy}>Privacy Policy</SecondaryLink>
      <SecondaryLinkDivider />
      <SecondaryLink onClick={onClickCookieSettings} to="#">
        Cookie Settings
      </SecondaryLink>
      <SecondaryLinkDivider />
      <SecondaryLink to="https://bigidprivacy.cloud/consumer/#/EnSBtJyXmT/Form-NJkZtUEqYjcmwwr" external>
        Your Privacy Choices
        <img src={ccpaOptOutIcon} alt="California Consumer Privacy Act (CCPA) Opt-Out Icon" />
      </SecondaryLink>
      <SecondaryLinkDivider />
      <SecondaryLink to={MainPagePath.accessibility}>Accessibility Statement</SecondaryLink>
      <SecondaryLinkDivider />
      <SecondaryLink to={MainPagePath.californiaSupplyChain}>CA Supply Chains Act</SecondaryLink>
      <SecondaryLinkDivider />
      <SecondaryLink to="https://locations.jimmyjohns.com/" external>
        All JJ’s Locations
      </SecondaryLink>
      <SecondaryLinkDivider />
      <SecondaryLink to={MainPagePath.consumerHealthData}>Consumer Health Data</SecondaryLink>
    </Box>
  );
};

type SecondaryLinkProps = RouterLinkProps & {
  external?: boolean;
};

const SecondaryLink = ({ external = false, ...routerLinkProps }: SecondaryLinkProps) => (
  <Box>
    <Link
      component={RouterLink}
      underline="hover"
      variant="bodySmall"
      sx={styles.secondaryLink}
      {...routerLinkProps}
      {...(external ? { rel: "noreferrer", target: "_blank" } : {})}
    />
  </Box>
);

const SecondaryLinkDivider = () => (
  <Divider orientation="vertical" variant="middle" flexItem sx={styles.secondaryLinkDivider} aria-hidden="true" />
);

export default SecondaryLinks;
