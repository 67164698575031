import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ReactNode } from "react";
import Icon from "ui/components/Icon/Icon";
import styles from "ui/components/Modal/Modal.styles";

export type ModalProps = Omit<DialogProps, "onClose"> & {
  children: ReactNode;
  onClose: () => void;
  actions?: ReactNode;
  title?: string;
};

const Modal = ({ actions, children, onClose, title, ...dialogProps }: ModalProps) => {
  const theme = useTheme();
  const isMediumOrGreater = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Dialog fullScreen={!isMediumOrGreater} onClose={onClose} aria-label={title} {...dialogProps}>
      <DialogTitle component="div" className={title ? "has-title" : undefined}>
        {title ? <Typography variant="h4">{title}</Typography> : null}
        <IconButton
          onClick={onClose}
          sx={styles.closeButton}
          className={isMediumOrGreater ? "close-button-large" : "close-button-medium"}
          aria-label="close"
        >
          <Icon icon="closeDark" size={isMediumOrGreater ? "large" : "medium"} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={styles.content}>{children}</DialogContent>
      {actions && (
        <DialogActions>
          <Box sx={styles.actions}>{actions}</Box>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default Modal;
