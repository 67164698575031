import { useAnalytics } from "analytics";
import { observer } from "mobx-react-lite";
import { createContext, ReactNode } from "react";
import { AlertStorable } from "stores/alert/AlertStorable";
import AlertStore from "stores/alert/AlertStore";

export const AlertContext = createContext<AlertStorable | null>(null);

type ContextProviderProps = {
  children: ReactNode;
};

const AlertProvider = observer(({ children }: ContextProviderProps) => {
  const { emitAnalyticsEvent } = useAnalytics();
  const store: AlertStorable = new AlertStore(emitAnalyticsEvent);
  return <AlertContext.Provider value={store}>{children}</AlertContext.Provider>;
});

export default AlertProvider;
