import mParticle from "@mparticle/web-sdk";
import AnalyticsEventRegistry, { AnalyticsProduct } from "analytics/AnalyticsEventRegistry";
import { logCustomEvent, logProductAction } from "analytics/vendors/mParticle/mParticle.utils";
import { useCallback, useEffect, useRef } from "react";
import { useMenu } from "stores/menu";
import EventEmitter from "util/EventEmitter";
import { currencyStringToNumber } from "util/StringUtils";

const useMParticleEventTracking = (emitter: EventEmitter<AnalyticsEventRegistry>) => {
  const eventRegistrationRef = useRef(false);
  const menuStore = useMenu();

  const mParticleProductFromAnalyticsProduct = useCallback(
    (analyticsProduct: AnalyticsProduct) => {
      const menuProduct = menuStore.products?.find((menuProduct) => menuProduct.id === analyticsProduct.id);

      if (!menuProduct) return;

      const name = menuProduct.name;
      const price = analyticsProduct.price ? Number(analyticsProduct.price.replace(/[^0-9.-]+/g, "")) : 0;
      const quantity = analyticsProduct.quantity ?? 1;
      const sku = menuProduct.id;

      return mParticle.eCommerce.createProduct(name, sku, price, quantity);
    },
    [menuStore.products]
  );

  /** Register all Optimizely event listeners */
  useEffect(() => {
    // make sure that we only register these events once
    if (eventRegistrationRef.current) return;
    eventRegistrationRef.current = true;

    emitter.on("add_to_cart", ({ products, order }) => {
      const mParticleProducts = products
        .map((analyticsProduct) => mParticleProductFromAnalyticsProduct(analyticsProduct))
        .filter(Boolean) as mParticle.Product[];

      logProductAction(mParticle.ProductActionType.AddToCart, {
        products: mParticleProducts,
        orderId: order.id,
        storeId: order.store.id,
        storeName: order.store.name,
      });
    });

    emitter.on("checkout", ({ products, order }) => {
      const mParticleProducts = products
        .map((analyticsProduct) => mParticleProductFromAnalyticsProduct(analyticsProduct))
        .filter(Boolean) as mParticle.Product[];

      logProductAction(mParticle.ProductActionType.Checkout, {
        products: mParticleProducts,
        orderId: order.id,
        storeId: order.store.id,
        storeName: order.store.name,
      });
    });

    emitter.on("purchase", ({ order }) => {
      const analyticsProducts = order.items.map(({ product, ...rest }) => ({ ...rest, id: product.id }));
      const mParticleProducts = analyticsProducts
        .map((analyticsProduct) => mParticleProductFromAnalyticsProduct(analyticsProduct))
        .filter(Boolean) as mParticle.Product[];

      const totalStr = order.totals.find(({ id }) => id === "total")?.value ?? "";
      const total = currencyStringToNumber(totalStr);

      logProductAction(mParticle.ProductActionType.Checkout, {
        products: mParticleProducts,
        orderId: order.id,
        revenue: total,
        storeId: order.store.id,
        storeName: order.store.name,
      });
    });

    emitter.on("sign_in", ({ step }) => {
      if (step === "Success") {
        logCustomEvent("Login");
      }
    });

    emitter.on("sign_out", () => {
      logCustomEvent("Logout");
    });

    emitter.on("view_product", ({ product, order }) => {
      const mParticleProduct = mParticleProductFromAnalyticsProduct(product);

      if (!mParticleProduct) return;

      logProductAction(mParticle.ProductActionType.ViewDetail, {
        products: [mParticleProduct],
        orderId: order?.id,
        storeId: order?.store.id,
        storeName: order?.store.name,
      });
    });
  }, [emitter, mParticleProductFromAnalyticsProduct]);
};

export default useMParticleEventTracking;
