import MuiStyles from "ui/util/Styles";

const styles = {
  secondaryLink: {
    alignItems: "center",
    color: "grey.300",
    display: "flex",
    flexDirection: "row",
    gap: "0.25rem",
    p: 0,
    width: "fit-content",

    "&:active": {
      color: "white",
    },

    "&:focus-visible": {
      borderColor: "transparent",
      borderWidth: "0 0 1px 0",
      outlineColor: "primary.light",
      outlineStyle: "solid",
      outlineWidth: "0.25rem",
      padding: "0.0625rem",
    },

    "&:hover": {
      borderBottomColor: "primary.main",
      color: "grey.300",
    },

    "&:visited": {
      color: "grey.300",
    },
  },
  secondaryLinkDivider: {
    borderColor: "grey.600",
    display: {
      xs: "none",
      lg: "inline-block",
    },
    height: "1.5rem",
  },
  secondaryLinks: {
    alignItems: "center",
    display: "inline-flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: {
      xs: "unset",
      lg: "1rem",
    },
    paddingTop: "2rem",

    "& > div": {
      padding: "0.5rem 0",
      width: {
        xs: "50%",
        lg: "auto",
      },
    },
  },
} satisfies MuiStyles;

export default styles;
