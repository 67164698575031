import { createInstance, enums, ReactSDKClient } from "@optimizely/react-sdk";
import { UserInfo } from "@optimizely/react-sdk/dist/utils";
import { UserAttributes } from "analytics/vendors/Optimizely/Optimizely.types";
import { readCookie } from "util/CookieUtils";
import isDevelopment from "util/isDevelopment";
import LocalStorage from "util/LocalStorage";
import { logError } from "util/Logger";
import { v4 as uuidv4 } from "uuid";

let optimizelyClientInstance: ReactSDKClient;

/**
 * Instantiate and/or return a pre-configured instance of the Optimizely React client SDK (singleton/global).
 * @see {@link https://docs.developers.optimizely.com/feature-experimentation/docs/initialize-sdk-react#instantiate-using-sdk-key | Optimizely React SDK Documentation}
 */
export const getOptimizelyClient = () => {
  if (optimizelyClientInstance) {
    return optimizelyClientInstance;
  }

  optimizelyClientInstance = createInstance({
    sdkKey: Env.OptimizelyKey,
    logLevel: isDevelopment ? enums.LOG_LEVEL.DEBUG : enums.LOG_LEVEL.ERROR,
    logger: {
      log: (logLevel, message: string) => {
        // in production, use remote error logging
        if (!isDevelopment) {
          logError(message);
          return;
        }

        // in development, use console logging
        switch (logLevel) {
          case enums.LOG_LEVEL.DEBUG:
            // eslint-disable-next-line no-console
            console.debug(message);
            break;
          case enums.LOG_LEVEL.INFO:
            // eslint-disable-next-line no-console
            console.info(message);
            break;
          case enums.LOG_LEVEL.WARNING:
            // eslint-disable-next-line no-console
            console.warn(message);
            break;
          case enums.LOG_LEVEL.ERROR:
            // eslint-disable-next-line no-console
            console.error(message);
            break;
        }
      },
    },
  });

  return optimizelyClientInstance;
};

/**
 * @returns an Optimizely UserInfo object.
 */
export const getOptimizelyUser = (): UserInfo => ({
  id: getOptimizelyUserId(),
  attributes: getOptimizelyUserAttributes(),
});

/**
 * Gets the user's Optimizely ID from local storage (if available);
 * otherwise, creates a new ID, stores it, and returns it.
 *
 * This ID enables Optimizely to bucket the user — or, more accurately,
 * the user's browser — so that they are consistently shown (or not shown)
 * features/experiments.
 *
 * @returns a persistent anonymous Optimizely user ID
 */
export const getOptimizelyUserId = () => {
  const optimizelyUserIdStore = new LocalStorage<string>("OptimizelyUserId");
  const optimizelyUserId = optimizelyUserIdStore.get();

  if (optimizelyUserId) return optimizelyUserId;

  const newOptimizelyUserId = uuidv4();
  optimizelyUserIdStore.set(newOptimizelyUserId);

  return newOptimizelyUserId;
};

/**
 * Creates & returns a user attributes object.
 * @returns a user attributes object
 */
export const getOptimizelyUserAttributes = () => {
  // use `testAudience` cookie value; used for canary testing unreleased features
  const testAudienceCookieValue = readCookie("testAudience");

  if (!testAudienceCookieValue) return;

  return {
    testAudience: testAudienceCookieValue,
  };
};

/**
 * Update a user attribute for Optimizely Web Experimentation tracking
 * @see {@link https://docs.developers.optimizely.com/web-experimentation/reference/user | Optimizely Web Experimentation Documentation}
 */
export const updateOptimizelyUserAttributes = (attributes: Partial<UserAttributes>) => {
  try {
    window.optimizely?.push({ type: "user", attributes });
  } catch (error) {
    logError(error);
  }
};
