import { AnalyticsErrorCategory, EcommerceItem } from "analytics/vendors/GoogleAnalytics/GoogleAnalytics.types";
import { ErrorType, MenuError, OrderError, StoreError, UserAccountError } from "errors/Errors";
import { FilterOption } from "models/Filter";
import { ProductItem } from "models/ProductItem";
import { ProductModifier } from "ui/screens/Product/ProductModifiers/ProductModifierManager";
import { currencyStringToNumber } from "util/StringUtils";

export const calculateProductPrice = (product: ProductItem, modifiers: ProductModifier[] = []) => {
  const basePrice = currencyStringToNumber(product.dynamicData.price ?? "") ?? 0;

  const modifiersTotalInCents = modifiers.reduce((modifiersTotal, modifier) => {
    if (!modifier.enabled) return modifiersTotal;

    const selectedOptionId = modifier.selectedOptions[0];
    const selectedOption = modifier.options.find((option) => option.id === selectedOptionId);
    const modifierCost = selectedOption?.price ?? 0;

    return modifiersTotal + modifierCost * 100;
  }, 0);

  return basePrice + modifiersTotalInCents / 100;
};

export const calculateTotalCostOfItems = (items: EcommerceItem[]) => {
  const totalInCents = items.reduce((total, { price = 0 }) => {
    return total + price * 100;
  }, 0);

  return totalInCents / 100;
};

export const eccomerceItemFromCategory = ({
  category,
  index,
  listName,
}: {
  category: FilterOption;
  index: number;
  listName: string;
}): EcommerceItem => ({
  index,
  is_catering: category.menuType === "catering" ? "Yes" : "No",
  item_category: listName,
  item_id: category.id,
  item_name: category.name,
  item_list_name: listName,
});

export const errorTypeToAnalyticsErrorCategory = (errorType: ErrorType): AnalyticsErrorCategory => {
  switch (errorType) {
    case OrderError.AddFave:
    case OrderError.AddItem:
    case OrderError.AddMultipleItems:
    case OrderError.AddUpsell:
    case OrderError.CreateOrder:
    case OrderError.FetchOrder:
    case OrderError.ModifyItem:
    case OrderError.ModifyOrderType:
    case OrderError.RemoveItem:
    case OrderError.RepeatOrder:
    case OrderError.ValidateOrder:
      return "Bag Error";

    case OrderError.CancelOrder:
      return "Cancel Order Error";

    case OrderError.CheckIn:
      return "Check In Error";

    case OrderError.ApplyCoupon:
    case OrderError.RedeemReward:
    case OrderError.RemoveCoupon:
    case OrderError.RemoveReward:
      return "Checkout Error - Discount";

    case OrderError.SubmitOrder:
      return "Checkout Error - Payment";

    case UserAccountError.ChangePassword:
    case UserAccountError.ChangeEmail:
    case UserAccountError.CreateFave:
    case UserAccountError.DeleteAccount:
    case UserAccountError.FetchFaves:
    case UserAccountError.FetchRecentOrders:
    case UserAccountError.FetchRewards:
    case UserAccountError.FetchSavedDeliveryAddresses:
    case UserAccountError.FetchSavedLocations:
    case UserAccountError.FetchSavedPayments:
    case UserAccountError.FetchUser:
    case UserAccountError.RemoveFave:
    case UserAccountError.RemoveSavedPayment:
    case UserAccountError.SavePaymentMethod:
    case UserAccountError.SetDefaultDeliveryAddress:
    case UserAccountError.SetDefaultLocation:
      return "Profile Error";

    case UserAccountError.LogIn:
      return "Sign Up Error";

    case UserAccountError.CreateAccount:
      return "Sign Up Error";

    case StoreError.FetchOrderAheadTimes:
    case StoreError.SearchStores:
      return "Store Error";

    case MenuError.General:
    case OrderError.ApplyTip:
    case UserAccountError.LogOut:
    default:
      return "General Error";
  }
};

export const getComboModifiers = (productModifiers: ProductModifier[]) => {
  return productModifiers.filter(
    (modifier) => modifier.enabled && (modifier.text === "Your Side" || modifier.text === "Your Drink")
  );
};

export const getSelectedSizeOption = (productModifiers: ProductModifier[]) => {
  const sizeModifier = productModifiers.find(
    (modifier) =>
      modifier.enabled && (modifier.text === "Choose Your Size" || modifier.text === "Choose Your Combo Size")
  );

  const sizeSelectedOptionId =
    sizeModifier && sizeModifier.selectedOptions.length ? sizeModifier.selectedOptions[0] : undefined;
  const sizeSelectedOption = sizeModifier && sizeModifier.options.find((option) => option.id === sizeSelectedOptionId);

  return sizeSelectedOption;
};
