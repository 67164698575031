import "@splidejs/react-splide/css";
import { Box, Button } from "@mui/material";
import { Splide } from "@splidejs/react-splide";
import { useAnalytics } from "analytics";
import AchievementBadge from "models/rewards/AchievementBadge";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "ui/components/AchievementBadgeModal/AchievementBadgeModal.styles";
import Badge from "ui/components/AchievementBadgeModal/Badge";
import Modal, { ModalProps } from "ui/components/Modal";
import { MainPagePath } from "ui/navigation/Pages";

export type AchievementBadgeModalProps = Omit<ModalProps, "children" | "title"> & {
  badges: AchievementBadge[];
  onClose: () => void;
};

const AchievementBadgeModal = ({ badges, onClose, ...modalProps }: AchievementBadgeModalProps) => {
  const { emitAnalyticsEvent } = useAnalytics();
  const navigate = useNavigate();
  const [activeBadge, setActiveBadge] = useState<AchievementBadge>(badges[0]);

  // report badge impression to analytics
  useEffect(() => {
    if (!modalProps.open) return;
    emitAnalyticsEvent("view_badge_modal", { badge: activeBadge });
  }, [activeBadge, modalProps.open, emitAnalyticsEvent]);

  const onChangeActiveSlide = (activeSlideIndex: number) => {
    setActiveBadge(badges[activeSlideIndex]);
  };

  const onClickCancel = (event: React.MouseEvent<HTMLElement>) => {
    onClose();

    emitAnalyticsEvent("click_badge_modal_cta", {
      badge: activeBadge,
      cta: event ? event.currentTarget.innerText : "Close",
    });
  };

  const onClickClose = () => {
    onClose();

    emitAnalyticsEvent("click_badge_modal_cta", {
      badge: activeBadge,
      cta: "Close",
    });
  };

  const onClickGoToMenu = (event: React.MouseEvent<HTMLElement>) => {
    navigate(MainPagePath.menu);
    onClose();

    emitAnalyticsEvent("click_badge_modal_cta", {
      badge: activeBadge,
      cta: event.currentTarget.innerText,
    });
  };

  const actions = [
    <Button key="cancel" onClick={onClickCancel} variant="text">
      Cancel
    </Button>,
    <Button key="go-to-menu" onClick={onClickGoToMenu}>
      Go To Menu
    </Button>,
  ];

  return (
    <Modal {...modalProps} actions={actions} onClose={onClickClose} aria-label="achievement badge modal">
      <Box component={Splide} onActive={(_, { index }) => onChangeActiveSlide(index)} sx={styles.carousel}>
        {badges.map((badge) => (
          <Badge badge={badge} key={badge.id} />
        ))}
      </Box>
    </Modal>
  );
};

export default AchievementBadgeModal;
