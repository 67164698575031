import AnalyticsEventRegistry from "analytics/AnalyticsEventRegistry";
import { OptimizelyEvent, OptimizelyTags } from "analytics/vendors/Optimizely/Optimizely.types";
import { useEffect, useRef } from "react";
import { useMenu } from "stores/menu";
import EventEmitter from "util/EventEmitter";
import isDevelopment from "util/isDevelopment";

const useOptimizelyEventTracking = (emitter: EventEmitter<AnalyticsEventRegistry>) => {
  const eventRegistrationRef = useRef(false);
  const menuStore = useMenu();

  /**
   * Track an event for an Optimizely Web Experimentation project
   * @see {@link https://docs.developers.optimizely.com/web-experimentation/reference/event | Optimizely Web Experimentation Documentation}
   */
  const trackEvent = (event: OptimizelyEvent, tags?: OptimizelyTags) => {
    window.optimizely = window.optimizely || [];
    window.optimizely.push({ type: "event", event, tags });

    // eslint-disable-next-line no-console
    if (isDevelopment) console.debug("Optimizely event:", { event, tags });
  };

  /** Register all Optimizely event listeners */
  useEffect(() => {
    // make sure that we only register these events once
    if (eventRegistrationRef.current) return;
    eventRegistrationRef.current = true;

    const getMenuProductById = (id: string) => {
      return menuStore.products?.find((menuProduct) => menuProduct.id === id);
    };

    emitter.on("add_cross_sell_to_cart", ({ placement, productName }) => {
      trackEvent("cross_sell_click", {
        item_name: productName,
        placement,
      });
    });

    emitter.on("add_to_cart", ({ products }) => {
      products.forEach((analyticsProduct) => {
        const menuProduct = getMenuProductById(analyticsProduct.id);

        if (!menuProduct) return;

        trackEvent("atb_click", {
          item_name: menuProduct.name,
        });
      });
    });

    emitter.on("checkout", () => {
      trackEvent("checkout_click");
    });

    emitter.on("click_banner_cta", ({ banner, cta }) => {
      trackEvent("banner_click", {
        banner_name: banner,
        link_text: cta,
      });
    });

    emitter.on("click_gift_card_cta", ({ gift_card, cta }) => {
      trackEvent("banner_click", {
        banner_name: gift_card,
        link_text: cta,
      });
    });

    emitter.on("click_homepage_category", ({ categoryName, listName }) => {
      trackEvent("homepage_card_click", {
        item_list_name: listName,
        item_name: categoryName,
      });
    });

    emitter.on("create_account", ({ step }) => {
      if (step === "Success") {
        trackEvent("sign_up_click");
      }
    });

    emitter.on("modify_item", ({ modification, productName, selectedOption }) => {
      trackEvent("product_modify_click", {
        item_name: productName,
      });

      if (modification.match(/^Make it a Combo/) && selectedOption.match(/^Yes/)) {
        trackEvent("make_it_a_combo_click", {
          item_name: productName,
        });
      }
    });

    emitter.on("purchase", ({ order, payments }) => {
      const orderTotal = order.totals.find(({ id }) => id === "total")?.value ?? "";
      const paymentTypes = payments.map(({ type }) => type).join();

      trackEvent("purchase_event", {
        handoff_type: order.orderType,
        payment_method: paymentTypes,
        revenue: orderTotal,
      });
    });

    emitter.on("select_location", ({ storeName }) => {
      trackEvent("select_location_click", { location_selection: storeName });
    });

    emitter.on("select_product", ({ product }) => {
      const menuProduct = getMenuProductById(product.id);

      if (!menuProduct) return;

      trackEvent("pdp_click", {
        item_name: menuProduct.name,
      });
    });

    emitter.on("select_reward", ({ placement, reward }) => {
      trackEvent("redeem_reward_click", {
        reward_name: reward,
        reward_placement: placement,
      });
    });

    emitter.on("sign_in", ({ step }) => {
      if (step === "Success") {
        trackEvent("sign_in_click");
      }
    });

    emitter.on("view_product", ({ product }) => {
      const menuProduct = getMenuProductById(product.id);

      if (!menuProduct) return;

      trackEvent("pdp_view", {
        item_name: menuProduct.name,
      });
    });
  }, [emitter, menuStore.products]);
};

export default useOptimizelyEventTracking;
