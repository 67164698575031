import MuiStyles from "ui/util/Styles";

const styles = {
  ctasEmpty: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
    justifyContent: "space-around",
    paddingX: "1rem",
    width: "100%",

    "& .emptyCta, .MuiButton-outlined.emptyCta": {
      paddingX: "revert",
    },
  },
  empty: (theme) => ({
    alignItems: "center",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    gap: "1.5rem",
    justifyContent: "center",
    margin: theme.spacing(3, 2, 6),
    textAlign: "center",

    [theme.breakpoints.down("md")]: {
      marginX: "auto",
      maxWidth: theme.spacing(49),
    },
  }),
  emptyBagText: {
    display: "flex",
    flexDirection: "column",
    gap: 0.5,
    paddingX: "1rem",
  },
} as const satisfies MuiStyles;

export default styles;
