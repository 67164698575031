export const capitalize = (word: string) => {
  /**
   * Captures any of the following:
   *
   * The first character of a word
   * The first character after some white space
   * The first character after a dash (-)
   * The first character after a period (.)
   **/
  const regex = /(^\w|\s\w|-\w|\.\w)/g;

  return word.toLowerCase().replace(regex, (c) => c.toUpperCase());
};

export const currencyStringToNumber = (currencyStr: string) => {
  return Number(currencyStr.replace(/[^0-9.-]+/g, "")) || undefined;
};
