/** This enum comprises all category/product/modifier `metadata` object keys utilized by the application. */
enum MetadataKey {
  /** e.g. "FFCC33" */
  BannerBackgroundColor = "banner-bgcolor",

  /** e.g. "NEW" */
  BannerText = "banner-text",

  /** e.g. "false" */
  CanGroupOrder = "can-group-order",

  /** e.g. "XTRA" (modifier selection/order summary) */
  DisplayGroupName = "display-group-name",

  /** e.g. "false" (should hide from order summary) */
  DisplayInBasket = "display-in-basket",

  /** e.g. "Choose Sandwiches (catering)"*/
  DisplayTitle = "display-title",

  /** e.g. "true" */
  LoyaltyOnly = "loyalty-only",

  /** e.g. "sandwich", "sides", or "drinks" */
  MenuCategoryType = "menu-category-type",

  /** e.g. "catering", "regular" */
  MenuView = "menu-view",

  /** e.g. "included" (customizations/modifiers) */
  ModifierAppGroupName = "app-modgroup-column-name",

  /** e.g. "add-ons" (customizations/modifiers) */
  ModifierGroupName = "modgroup-column-name",

  //e.g. "sandwich-selection" (used in catering)
  ModifierGroupType = "modgroup-type",

  /** e.g. "customize" (customizations/modifiers) */
  ModifierView = "view",

  /** e.g. "Add Drink" (sandwich product detail page) */
  ProductDropdownGroup = "product-dropdown-group",

  /** e.g. "Desserts" (sides/drink subgroups) */
  ProductSubgroup = "product-subgroup",

  /** e.g. "party-box"*/
  ProductView = "product-view",

  /** e.g. "1200" */
  SecondCalRange = "2nd-cal-range",

  /** SEO meta description */
  SeoDescription = "seo-desc",

  /** SEO title string */
  SeoTitle = "seo-title",

  /** e.g. " ea." */
  ShowServings = "show-servings",

  /** e.g. We will scoop bread out of the top shell if you select this option." (bread options) */
  TooltipText = "tooltip-text",

  /** e.g. "billy-club" – used for SEO vanity URLs */
  UrlSlug = "jj-url",
}

/** Metadata value type for MetadataKey.MenuCategoryType (e.g. `{ key: "menu-category-type", value: "drinks" }`) */
export enum MenuCategoryType {
  Drinks = "drinks",
  Sandwich = "sandwich",
  Sides = "sides",
}

export const isMenuCategoryType = (object: unknown): object is MenuCategoryType => {
  return (
    object === MenuCategoryType.Drinks || object === MenuCategoryType.Sandwich || object === MenuCategoryType.Sides
  );
};

export default MetadataKey;
