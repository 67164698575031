import { AnalyticsContext } from "analytics/AnalyticsProvider";
import { useContext } from "react";

const useAnalytics = () => {
  const analytics = useContext(AnalyticsContext);
  if (!analytics) {
    throw new Error("useAnalytics must be used in a child of an <Analytics> component");
  }
  return {
    emitAnalyticsEvent: analytics.emit,
  };
};

export default useAnalytics;
