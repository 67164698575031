import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import { CartItem } from "models/order/CartItem";
import { Fragment } from "react";
import styles from "ui/components/Cart/CartItemList/CartItemList.styles";
import CartItemView from "ui/components/Cart/CartItemList/CartItemView";
import { CartItemViewProps } from "ui/components/Cart/CartItemList/CartItemView";

export type CartItemListProps = {
  cartItems: CartItem[];
  CartItemViewProps?: Omit<CartItemViewProps, "cartItem" | "showRecipient">;
  groupByRecipient?: boolean;
};

const CartItemList = ({ groupByRecipient = false, ...props }: CartItemListProps) => {
  return groupByRecipient ? <CartItemListGrouped {...props} /> : <CartItemListUngrouped {...props} />;
};

const CartItemListGrouped = ({ cartItems, CartItemViewProps = {} }: CartItemListProps) => {
  const cartItemGroups = _.groupBy(cartItems, "recipient");

  return (
    <Stack sx={styles.container}>
      {Object.entries(cartItemGroups).map(([groupName, items]) => (
        <Fragment key={groupName}>
          {groupName !== "NO NAME" && (
            <Typography variant="body2Bold" sx={styles.groupHeading}>
              {groupName}
            </Typography>
          )}
          {items.map((cartItem) => (
            <Box key={cartItem.id}>
              <CartItemView cartItem={cartItem} showRecipient={false} {...CartItemViewProps} />
            </Box>
          ))}
        </Fragment>
      ))}
    </Stack>
  );
};
const CartItemListUngrouped = ({ cartItems, CartItemViewProps = {} }: CartItemListProps) => (
  <Stack sx={styles.container}>
    {cartItems.map((cartItem) => (
      <Box key={cartItem.id}>
        <CartItemView cartItem={cartItem} {...CartItemViewProps} />
      </Box>
    ))}
  </Stack>
);

export default CartItemList;
