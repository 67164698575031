import { UserDataTransformer } from "dataTransformers/UserDataTransformer";
import { observer } from "mobx-react-lite";
import { createContext, ReactNode } from "react";
import RootStore from "stores/RootStore";
import { UserStorable } from "stores/user/UserStorable";
import UserStore from "stores/user/UserStore";

export const UserContext = createContext<UserStorable | null>(null);

type ContextProviderProps = {
  children: ReactNode;
};

const UserProvider = observer(({ children }: ContextProviderProps) => {
  const store: UserStorable = new UserStore(new UserDataTransformer(RootStore.networker, RootStore.tokenManager));
  return <UserContext.Provider value={store}>{children}</UserContext.Provider>;
});

export default UserProvider;
