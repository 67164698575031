import MuiStyles from "ui/util/Styles";

const styles = {
  participantsBanner: (theme) => ({
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    minHeight: theme.pxToRem(50),
    background: theme.palette.grey[100],
    padding: theme.spacing(2),
  }),
  addParticipantsButton: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    gap: "0.5rem",
  },
} as const satisfies MuiStyles;

export default styles;
