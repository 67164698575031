import useCms from "hooks/useCms";
import { markPromoModalAsSeen, shouldDisplayPromoModal } from "hooks/usePromoModal.helpers";
import { PromoModalData } from "models/cms/DataTypes/CmsPromoModalData";
import { isGroupOrder } from "models/groupOrder";
import { useEffect, useMemo, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { useOrder } from "stores/order";
import { GroupOrderPagePath } from "ui/navigation/Pages";
import { logError } from "util/Logger";

const appRouteDenyList = [GroupOrderPagePath.join];

/**
 * Fetches and returns any displayable promo modals from the CMS.
 * When `promoModal` is `undefined`, the fetch has not yet completed.
 * When `promoModal` is `null`, the fetch has completed with no displayable promo modal.
 * Will only fetch for promo modals on app routes that allow it.
 */
const usePromoModal = (now = new Date()) => {
  const { pathname } = useLocation();
  const { getPromoModals } = useCms();
  const { order, isLoadingOrder } = useOrder();
  const [promoModals, setPromoModals] = useState<PromoModalData[]>();

  const isAllowedToFetch = useMemo(() => {
    // do not display a promo modal while the order is being loaded
    // or if the current order is a group order
    if (isLoadingOrder || isGroupOrder(order)) return false;
    return !appRouteDenyList.some((deniedRoute) => matchPath(deniedRoute, pathname));
  }, [isLoadingOrder, order, pathname]);

  // fetch promo modal entries
  useEffect(() => {
    const fetchPromoModals = async () => {
      try {
        const promoModals = await getPromoModals();
        setPromoModals(promoModals);
      } catch (e) {
        logError(e);
      }
    };

    if (!isAllowedToFetch) return;

    fetchPromoModals();
  }, [getPromoModals, isAllowedToFetch]);

  const promoModal = useMemo(() => {
    if (!promoModals) return;

    const displayablePromoModal = promoModals.find((promoModal) => shouldDisplayPromoModal(promoModal, now));

    return displayablePromoModal ?? null;
  }, [now, promoModals]);

  return {
    markPromoModalAsSeen: (promoModalId: string) => markPromoModalAsSeen(promoModalId, now),
    promoModal,
  };
};

export default usePromoModal;
