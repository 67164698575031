import { Button, Stack } from "@mui/material";
import styles from "ui/components/Cart/GroupOrderButton/GroupOrderButton.styles";
import Icon from "ui/components/Icon";

const GroupOrderButton = ({ onClick }: { onClick: () => void }) => (
  <Stack sx={styles.groupOrderButton}>
    <Icon icon="groupOrder" />
    <Button onClick={onClick} variant="text">
      Make this a group order
    </Button>
  </Stack>
);

export default GroupOrderButton;
