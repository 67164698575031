import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Divider,
  Fade,
  Link as MuiLink,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import useRedirectToPreviousLocation from "hooks/useRedirectToPreviousLocation";
import { observer } from "mobx-react-lite";
import { useRef } from "react";
import { Link } from "react-router-dom";
import styles from "ui/app/GlobalNav/NavUser/NavUser.styles";
import useViewModel from "ui/app/GlobalNav/NavUser/NavUserViewModel";
import GlobalNavLink from "ui/components/GlobalNavLink/GlobalNavLink";
import Icon from "ui/components/Icon/Icon";
import NavPopover from "ui/components/NavPopover/NavPopover";
import { AccountPagePath } from "ui/navigation/Pages";

type NavUserProps = {
  navRef?: React.RefObject<HTMLElement>;
};

const NavUser = observer(({ navRef }: NavUserProps) => {
  const { redirectState } = useRedirectToPreviousLocation();
  const { shouldShowPopover, handleTogglePopover, handleClosePopover, handleLogOutClick, onClickLogIn, user } =
    useViewModel();

  const theme = useTheme();
  const isSmallOrGreater = useMediaQuery(theme.breakpoints.up("sm"));
  const isLargeOrGreater = useMediaQuery(theme.breakpoints.up("lg"));
  const isExtraLargeOrGreater = useMediaQuery(theme.breakpoints.up("xl"));

  const iconSize = isExtraLargeOrGreater ? "medium" : "small";

  const linkRef = useRef<HTMLButtonElement>(null);

  if (!user) {
    return (
      <GlobalNavLink
        to={AccountPagePath.signIn}
        onClick={onClickLogIn}
        Icon={<Icon icon="account" size={isLargeOrGreater ? "medium" : "small"} />}
        state={redirectState}
      >
        Log In
      </GlobalNavLink>
    );
  }

  const popoverAnchor = isSmallOrGreater || !navRef || !navRef.current ? linkRef.current : navRef.current;

  return (
    <>
      <Fade in={true} timeout={200}>
        <MuiLink
          onClick={handleTogglePopover}
          sx={styles.navUserButton}
          component="button"
          aria-controls={shouldShowPopover ? "user-nav-menu" : undefined}
          aria-expanded={shouldShowPopover ? "true" : undefined}
          aria-haspopup="true"
          underline="none"
          ref={linkRef}
        >
          <Stack direction="row" alignItems="flex-end">
            <Stack alignItems="flex-start">
              <Box component="span" sx={styles.buttonMessage}>
                Hi there,
              </Box>
              <Box component="span" sx={styles.buttonFirstName}>
                {user.firstName}
                {shouldShowPopover ? (
                  <KeyboardArrowUpIcon fontSize={iconSize} />
                ) : (
                  <KeyboardArrowDownIcon fontSize={iconSize} />
                )}
              </Box>
            </Stack>
          </Stack>
        </MuiLink>
      </Fade>
      <NavPopover anchorEl={popoverAnchor} open={shouldShowPopover} onClose={handleClosePopover}>
        <List disablePadding role="menu" aria-label="submenu">
          <ListItem disablePadding role="menuitem">
            <ListItemButton
              component={Link}
              to={AccountPagePath.profile}
              onClick={handleClosePopover}
              sx={styles.listItem}
              disableRipple
            >
              <ListItemText primary="Profile" primaryTypographyProps={{ variant: "body2Bold" }} />
              <Divider sx={styles.listDivider} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding role="menuitem">
            <ListItemButton
              component={Link}
              to={AccountPagePath.recentOrders}
              onClick={handleClosePopover}
              sx={styles.listItem}
              disableRipple
            >
              <ListItemText primary="Orders" primaryTypographyProps={{ variant: "body2Bold" }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding role="menuitem">
            <ListItemButton onClick={handleLogOutClick} sx={[styles.listItem, styles.listItemSecondary]} disableRipple>
              <ListItemText primary="Log Out" primaryTypographyProps={{ variant: "body2" }} />
            </ListItemButton>
          </ListItem>
        </List>
      </NavPopover>
    </>
  );
});

export default NavUser;
