import MuiStyles from "ui/util/Styles";

const styles = {
  cartContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
    overflowY: "scroll",
  },
  groupOrderButtonWrapper: {
    margin: "1rem 1rem 0.5rem",
  },
} as const satisfies MuiStyles;

export default styles;
