import { Box, Typography } from "@mui/material";
import Carousel from "ui/components/Carousel";
import styles from "ui/components/Cart/CartUpsell/CartUpsell.styles";
import CartUpsellCard from "ui/components/Cart/CartUpsell/CartUpsellCard";
import useViewModel, { CartUpsellViewModelProps } from "ui/components/Cart/CartUpsell/CartUpsellViewModel";

const CartUpsell = (viewModelProps: CartUpsellViewModelProps) => {
  const { isAddingToCart, onClickAddToCart, onClickModify, onImpression, onScroll, upsellProducts } =
    useViewModel(viewModelProps);

  if (!upsellProducts.length) return null;

  return (
    <Box sx={styles.container}>
      <Box sx={styles.heading}>
        <Typography variant="cardTitle" sx={styles.headingText}>
          Recommended For{" "}
          <Box component="span" sx={styles.headingTextCursive}>
            You
          </Box>
        </Typography>
      </Box>
      <Carousel
        options={{ arrows: false, rewind: true }}
        label="Recommended for you"
        onImpression={onImpression}
        onScroll={onScroll}
      >
        {upsellProducts.map((product) => (
          <CartUpsellCard
            isAddingToCart={isAddingToCart}
            key={product.id}
            onClickAddToCart={onClickAddToCart(product)}
            onClickModify={onClickModify(product)}
            product={product}
          />
        ))}
      </Carousel>
    </Box>
  );
};

export default CartUpsell;
