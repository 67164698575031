import { AnalyticsEventEmitter } from "analytics";
import { ErrorType } from "errors/Errors";
import { isOctocartError, OctocartError } from "errors/OctocartError";
import { action, makeObservable, observable } from "mobx";
import { Alert, RuntimeAlert } from "models/Alert";
import { AlertStorable } from "stores/alert/AlertStorable";

const DEFAULT_ERROR_MESSAGE = "An unexpected error occurred.";

class AlertStore implements AlertStorable {
  @observable alerts: Alert[] = [];
  private readonly emitAnalyticsEvent: AnalyticsEventEmitter;

  constructor(emitAnalyticsEvent: AnalyticsEventEmitter) {
    this.emitAnalyticsEvent = emitAnalyticsEvent;
    makeObservable(this);
  }

  @action addAlert = (alert: RuntimeAlert) => {
    if (this.alerts.filter((a) => a.type === alert.type && a.message === alert.message).length > 0) return;
    this.alerts = this.alerts.concat([alert]);
  };

  addErrorAlert = (errorType: ErrorType, error?: unknown, message?: string) => {
    if (message) {
      this.addAlert({ title: message });
      this.emitAnalyticsEvent("view_error", { displayError: message, type: errorType });
    } else if (isOctocartError(error)) {
      const displayableMessage = displayableMessageForApiError(error);
      this.addAlert({ title: displayableMessage });
      this.emitAnalyticsEvent("view_error", {
        displayError: displayableMessage,
        errorMessage: error.message,
        type: errorType,
      });
    } else {
      this.addAlert({ title: DEFAULT_ERROR_MESSAGE });
      this.emitAnalyticsEvent("view_error", { displayError: DEFAULT_ERROR_MESSAGE, type: errorType });
    }
  };

  @action clearAlerts = () => {
    this.alerts = [];
  };

  @action removeAlert = (alert: RuntimeAlert) => {
    this.alerts = this.alerts.filter((a) => !(a.type === alert.type && a.message === alert.message));
  };
}

/**
 * Return a user-friendly, displayable error message based on the error received from the API.
 * Error messages are passed-through and displayed to the user unless specifically handled here.
 *
 * @see [BE Errors](https://github.com/detroit-labs/jjs-web-backend/blob/d5d97dc50995a529a304a744470b315bf6578521/src/main/kotlin/com/detroitlabs/octocart/app/errorhandling/JsonCodeException.kt)
 * @see [Olo API Errors](https://developer.olo.com/docs/load/ordering-api#section/Olo-Definitions/Olo-Error-Numbers)
 */
const displayableMessageForApiError = (error: OctocartError) => {
  switch (error.code) {
    case "9001": // "Invalid CSRF Token"
      return "Session validation failed. Please try again.";
    default:
      return error.message;
  }
};

export default AlertStore;
