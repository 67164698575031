// eslint-disable-next-line @typescript-eslint/no-explicit-any
type EventCallback = (data: any) => void;
type DiscriminateEventCallback<T, Event extends keyof T> = T extends Record<Event, EventCallback> ? T[Event] : never;

export type EventRegistry = {
  [key: string]: EventCallback;
};

/**
 * Simple class for emitting events (aka pub/sub) for any use (i.e. usage tracking)
 *
 * ### Usage Example:
 * ```
 * // define your events and any data that should be included
 * interface AnalyticsEventRegistry extends EventRegistry {
 *   add_to_cart: (data: { productId: string; quantity: number; }) => void;
 * }
 *
 * // create an instance
 * const { emit, on } = new EventEmitter<AnalyticsEventRegistry>();
 *
 * // subscribe to an event (multiple subscriptions are allowed per event)
 * on("add_to_cart", ({ productId, quantity }) => {
 *   // do something with data (i.e. report to analytics)
 * });
 *
 * // then, wherever the event occurs in the app flow
 * emit("add_to_cart", { productId: "12345", quantity: 1 });
 * ```
 */
class EventEmitter<T extends EventRegistry> {
  private listeners: Partial<{
    [Property in keyof T]: Array<DiscriminateEventCallback<T, Property>>;
  }> = {};

  emit = <Event extends keyof T>(event: Event, ...params: Parameters<T[Event]>) => {
    // wrapping in promise to prevent main thread blocking
    return new Promise((resolve) => {
      const listeners = this.listeners[event];

      if (!listeners?.length) return;

      const [data] = params;

      listeners.forEach((eventHandler) => {
        eventHandler(data);
      });

      return resolve;
    });
  };

  on = <Event extends keyof T>(event: Event, onEvent: DiscriminateEventCallback<T, Event>) => {
    if (!this.listeners[event]) {
      this.listeners[event] = [];
    }

    this.listeners[event]?.push(onEvent);
  };
}

export default EventEmitter;
