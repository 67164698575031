import AnalyticsEventRegistry from "analytics/AnalyticsEventRegistry";
import useGoogleAnalyticsEventTracking from "analytics/vendors/GoogleAnalytics/useGoogleAnalyticsEventTracking";
import useMParticleEventTracking from "analytics/vendors/mParticle/useMParticleEventTracking";
import useOptimizelyEventTracking from "analytics/vendors/Optimizely/useOptimizelyEventTracking";
import { observer } from "mobx-react-lite";
import { createContext, PropsWithChildren } from "react";
import EventEmitter from "util/EventEmitter";

export type AnalyticsEventEmitter = <Event extends keyof AnalyticsEventRegistry>(
  event: Event,
  ...params: Parameters<AnalyticsEventRegistry[Event]>
) => void;

const analyticsEmitter = new EventEmitter<AnalyticsEventRegistry>();

export const AnalyticsContext = createContext<typeof analyticsEmitter | null>(null);

const AnalyticsProvider = observer(({ children }: PropsWithChildren) => {
  useGoogleAnalyticsEventTracking(analyticsEmitter);
  useMParticleEventTracking(analyticsEmitter);
  useOptimizelyEventTracking(analyticsEmitter);

  return <AnalyticsContext.Provider value={analyticsEmitter}>{children}</AnalyticsContext.Provider>;
});

export default AnalyticsProvider;
