import { LoadingButton } from "@mui/lab";
import { Box, Button } from "@mui/material";
import { MetaData } from "models/MetaData";
import { CartItem } from "models/order/CartItem";
import { OrderUpsellGroupItem } from "models/order/OrderUpsell";
import cartStyles from "ui/components/Cart/Cart.styles";
import CartItemList from "ui/components/Cart/CartItemList";
import CartUpsell from "ui/components/Cart/CartUpsell";
import styles from "ui/components/Cart/CartWithItems/CartWithItems.styles";
import GroupOrderButton from "ui/components/Cart/GroupOrderButton";
import OrderSummary from "ui/components/OrderSummary";

type CartWithItemsProps = {
  addUpsellToOrder: (productId: string, quantity: number) => Promise<void>;
  cartItems?: CartItem[];
  checkoutBtnLabel: string;
  handleAddFoodClick: () => void;
  handleCartItemRemove: (item: CartItem) => Promise<void>;
  handleCartItemQuantityChange?: (item: CartItem, quantity: number) => Promise<void>;
  handleCheckoutClick: () => void;
  handleMakeAGroupOrderClick: () => void;
  ledgerItems: MetaData[];
  shouldGroupCartItemsByRecipient: boolean;
  shouldShowCheckoutLoading: boolean;
  shouldShowEmptyCheckout: boolean;
  shouldShowGroupOrderButton?: boolean;
  smartUpsellItems?: OrderUpsellGroupItem[];
  staticUpsellItems?: OrderUpsellGroupItem[];
  total: string;
};

const CartWithItems = ({
  addUpsellToOrder,
  cartItems,
  checkoutBtnLabel,
  handleAddFoodClick,
  handleCartItemRemove,
  handleCartItemQuantityChange,
  handleCheckoutClick,
  handleMakeAGroupOrderClick,
  ledgerItems,
  shouldGroupCartItemsByRecipient,
  shouldShowCheckoutLoading,
  shouldShowGroupOrderButton,
  smartUpsellItems,
  staticUpsellItems,
  total,
}: CartWithItemsProps) => (
  <>
    {shouldShowGroupOrderButton && (
      <Box sx={styles.groupOrderButtonWrapper}>
        <GroupOrderButton onClick={handleMakeAGroupOrderClick} />
      </Box>
    )}
    <Box sx={styles.cartContent}>
      <Box>
        {cartItems && (
          <CartItemList
            cartItems={cartItems}
            CartItemViewProps={{
              modifiable: true,
              onRemove: handleCartItemRemove,
              onQuantityChange: handleCartItemQuantityChange,
            }}
            groupByRecipient={shouldGroupCartItemsByRecipient}
          />
        )}
        <CartUpsell
          addUpsellToOrder={addUpsellToOrder}
          smartUpsellItems={smartUpsellItems}
          staticUpsellItems={staticUpsellItems}
        />
      </Box>
      <OrderSummary ledgerItems={ledgerItems} total={total} />
    </Box>
    <Box sx={cartStyles.ctas}>
      <Button variant="outlined" onClick={handleAddFoodClick} sx={cartStyles.cta} role="link">
        Add Food
      </Button>
      <LoadingButton loading={shouldShowCheckoutLoading} onClick={handleCheckoutClick} sx={cartStyles.cta}>
        {checkoutBtnLabel}
      </LoadingButton>
    </Box>
  </>
);

export default CartWithItems;
