import MuiStyles from "ui/util/Styles";

const styles = {
  groupOrderButton: {
    alignItems: "center",
    flexDirection: "row",
    gap: "0.5rem",
  },
} as const satisfies MuiStyles;

export default styles;
