import { LoadingButton } from "@mui/lab";
import { Button, Stack, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import useViewModel, {
  AddParticipantsModalViewModelProps as ViewModelProps,
} from "ui/components/Cart/AddParticipantsModal/AddParticipantsViewModel";
import GroupOrderParticipantInvitesField from "ui/components/GroupOrderParticipantInvites/GroupOrderParticipantInvites";
import Modal from "ui/components/Modal";

export type AddParticipantsModalProps = ViewModelProps & {
  open: boolean;
};

const AddParticipantsModal = observer(({ onClose, open }: AddParticipantsModalProps) => {
  const {
    control,
    closeModal,
    fieldOptions,
    filteredSavedParticipants,
    groupOrderHostEmail,
    register,
    title,
    ...viewModel
  } = useViewModel({
    onClose,
  });

  const cancelButton = (
    <Button variant="text" onClick={closeModal} key="cancel">
      Cancel
    </Button>
  );

  const confirmButton = (
    <LoadingButton onClick={viewModel.onSubmit} loading={viewModel.isSubmitting} key="save">
      Send Invitations
    </LoadingButton>
  );

  return (
    <Modal actions={[cancelButton, confirmButton]} onClose={onClose} open={open} title={title}>
      <Stack gap={2}>
        <div>
          <Typography>
            Type as many email addresses as you&apos;d like using the Enter/Return key between entries.
          </Typography>
        </div>
        <div>
          <GroupOrderParticipantInvitesField
            control={control}
            fieldOptions={fieldOptions}
            groupOrderHostEmail={groupOrderHostEmail}
            register={register}
            savedParticipants={filteredSavedParticipants}
          />
        </div>
        <div>
          <Typography variant={"formFieldLabelCaps"}>Already Invited</Typography>
          <ul>
            {viewModel.currentGroupOrderParticipants.map((currentParticipant) => (
              <li key={currentParticipant}>
                <Typography>{currentParticipant}</Typography>
              </li>
            ))}
          </ul>
        </div>
      </Stack>
    </Modal>
  );
});

export default AddParticipantsModal;
