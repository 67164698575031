import { useAnalytics } from "analytics";
import { useOrder } from "stores/order";

const useOrderNavViewModel = () => {
  const { emitAnalyticsEvent } = useAnalytics();
  const { order } = useOrder();

  const onClickOrderNow = () => {
    emitAnalyticsEvent("click_link", { text: "Order Now", type: "Global Header" });
  };

  return {
    isOrderStarted: !!order,
    onClickOrderNow,
  };
};

export default useOrderNavViewModel;
