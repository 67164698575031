import { Box, Divider, Link, List, ListItem, Stack, Typography } from "@mui/material";
import { useAnalytics } from "analytics";
import AppStoreAppleSrc from "assets/app-store-apple.png";
import AppStoreGoogleSrc from "assets/app-store-google.png";
import Branding from "assets/Branding.svg";
import { Link as RouterLink } from "react-router-dom";
import styles from "ui/app/Footer/Footer.styles";
import LinkColumns, { Link as LinkType } from "ui/app/Footer/FooterLinkColumns";
import useViewModel from "ui/app/Footer/FooterViewModel";
import SecondaryLinks from "ui/app/Footer/SecondaryLinks";
import { Icon } from "ui/commerceUI/foundations/icons/Icon";
import Accordion from "ui/components/Accordion/Accordion";
import Colors from "ui/theme/Colors";

/**
 * Components/Navigation/Footers
 * https://www.figma.com/file/1Vve0S6Z5eCnPVpEvGKwtg/Jimmy-John's-Design-System---Web?node-id=14-49111&t=yxIH2IzWeUgcnBlc-0
 */

const Footer = () => {
  const { copyright, socialLinks } = useViewModel();

  return (
    <Box component="footer" sx={styles.container}>
      <DesktopSiteLinks />
      <MobileSiteLinks />
      <Divider orientation="horizontal" variant="middle" sx={styles.divider} aria-hidden="true" />
      <Box sx={styles.linksAndSocial}>
        <Stack flex={1} gap="1rem">
          <SecondaryLinks />
          <Typography variant="bodySmall" sx={styles.copyright}>
            {copyright}
          </Typography>
        </Stack>
        <SocialLinks socialLinks={socialLinks} />
      </Box>
    </Box>
  );
};

const DesktopSiteLinks = () => {
  const { emitAnalyticsEvent } = useAnalytics();

  return (
    <Box sx={[styles.siteLinks, styles.desktopSiteLinks]}>
      <Box component="img" alt={"jimmy johns logo"} sx={styles.logo} src={Branding} aria-hidden="true" />
      <Box sx={styles.siteMap}>
        {LinkColumns.map(({ title, links }) => (
          <Box sx={styles.column} key={title}>
            <Typography variant="formFieldLabelCaps" color={Colors.brightWhite}>
              {title}
            </Typography>
            <List disablePadding sx={styles.secodaryLinksTitle}>
              {links.map(({ url, label, type }) => {
                const onClick = () => emitAnalyticsEvent("click_link", { text: label, type: "Global Footer" });

                return (
                  <ListItem disablePadding key={url}>
                    {type ? (
                      <Link target="_blank" href={url} variant="bodySmall" underline="hover" onClick={onClick}>
                        {label}
                      </Link>
                    ) : (
                      <Link component={RouterLink} to={url} variant="bodySmall" underline="hover" onClick={onClick}>
                        {label}
                      </Link>
                    )}
                  </ListItem>
                );
              })}
            </List>
          </Box>
        ))}
      </Box>
      <AppStoreLinks />
    </Box>
  );
};

const MobileSiteLinks = () => {
  const { emitAnalyticsEvent } = useAnalytics();

  return (
    <Box sx={[styles.siteLinks, styles.mobileSiteLinks]}>
      <Box sx={styles.siteMap}>
        {LinkColumns.map(({ title, links }) => (
          <Accordion
            id={title}
            key={title}
            title={
              <Typography variant="formFieldLabelCaps" color={Colors.brightWhite}>
                {title}
              </Typography>
            }
            sx={styles.accordion}
            TransitionProps={{ unmountOnExit: true }}
          >
            {links.map(({ url, label, type }) => {
              const onClick = () => emitAnalyticsEvent("click_link", { text: label, type: "Global Footer" });

              return (
                <ListItem disablePadding key={url}>
                  {type ? (
                    <Link target="_blank" href={url} onClick={onClick}>
                      <Typography variant="bodySmall" color={Colors.gray300}>
                        {label}
                      </Typography>
                    </Link>
                  ) : (
                    <Link component={RouterLink} to={url} onClick={onClick}>
                      <Typography variant="bodySmall" color={Colors.gray300}>
                        {label}
                      </Typography>
                    </Link>
                  )}
                </ListItem>
              );
            })}
          </Accordion>
        ))}
      </Box>
      <AppStoreLinks />
    </Box>
  );
};

const AppStoreLinks = () => (
  <Box sx={styles.appStoreLinks}>
    <a
      href="https://apps.apple.com/us/app/jimmy-johns-in-app-ordering/id409366411"
      target="_blank"
      rel="noreferrer"
      className="imagelink"
      title="Download the app on Apple App Store"
    >
      <Box
        component="img"
        src={AppStoreAppleSrc}
        alt="Download the app on Apple App Store"
        sx={styles.appStoreLinkApple}
      />
    </a>
    <a
      href="https://play.google.com/store/apps/details?id=com.jimmyjohns"
      target="_blank"
      rel="noreferrer"
      className="imagelink"
      title="Download the app on Google Play"
    >
      <Box
        component="img"
        src={AppStoreGoogleSrc}
        alt="Download the app on Google Play"
        sx={styles.appStoreLinkGoogle}
      />
    </a>
  </Box>
);

const SocialLinks = ({ socialLinks }: { socialLinks: LinkType[] }) => {
  const links = socialLinks.map((sl) => {
    if (sl.icon) {
      return (
        <a aria-label={sl.label} key={sl.label} href={sl.url} target="_blank" rel="noreferrer" title={sl.label}>
          <Icon icon={sl.icon} color={Colors.brightWhite} />
        </a>
      );
    }
    return null;
  });

  return <Box sx={styles.socialLinks}>{links}</Box>;
};

export default Footer;
