import { LoadingButton } from "@mui/lab";
import { Box, Button, Typography } from "@mui/material";
import EmptyBagIcon from "assets/empty-bag.svg";
import cartStyles from "ui/components/Cart/Cart.styles";
import styles from "ui/components/Cart/EmptyCart/EmptyCart.styles";
import GroupOrderButton from "ui/components/Cart/GroupOrderButton";

type EmptyCartProps = {
  checkoutBtnLabel: string;
  handleAddFoodClick: () => void;
  handleCheckoutClick: () => void;
  handleMakeAGroupOrderClick: () => void;
  shouldShowCheckoutLoading: boolean;
  shouldShowEmptyCheckout: boolean;
  shouldShowGroupOrderButton?: boolean;
};

const EmptyCart = ({
  checkoutBtnLabel,
  handleAddFoodClick,
  handleCheckoutClick,
  handleMakeAGroupOrderClick,
  shouldShowCheckoutLoading,
  shouldShowEmptyCheckout,
  shouldShowGroupOrderButton,
}: EmptyCartProps) => (
  <Box sx={styles.empty}>
    <img src={EmptyBagIcon} alt="An empty bag for a Jimmy John's order" />
    <Box sx={styles.emptyBagText}>
      <Typography variant="h4">Your bag is empty</Typography>
      {shouldShowEmptyCheckout ? (
        <Typography variant="body1">Not hungry? No sweat. You can submit the Group Order with an empty bag.</Typography>
      ) : (
        <Typography variant="body1">
          We know you&rsquo;re hungry, so add some food to your order, and we&rsquo;ll get it to you freaky fast.
        </Typography>
      )}
    </Box>
    {shouldShowEmptyCheckout ? (
      <Box sx={styles.ctasEmpty}>
        <Button onClick={handleAddFoodClick} role="link" variant="outlined" sx={cartStyles.cta} className="emptyCta">
          Back to Menu
        </Button>
        <LoadingButton
          loading={shouldShowCheckoutLoading}
          onClick={handleCheckoutClick}
          sx={cartStyles.cta}
          className="emptyCta"
        >
          {checkoutBtnLabel}
        </LoadingButton>
      </Box>
    ) : (
      <Button onClick={handleAddFoodClick} role="link">
        Back to Menu
      </Button>
    )}

    {shouldShowGroupOrderButton && (
      <Box sx={{ paddingTop: "1.5rem" }}>
        <Typography>It’s more fun with friends!</Typography>
        <GroupOrderButton onClick={handleMakeAGroupOrderClick} />
      </Box>
    )}
  </Box>
);

export default EmptyCart;
