import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import ProductStore from "stores/menu/ProductStore";
import Card from "ui/components/Card";
import { CarouselItem } from "ui/components/Carousel";
import styles from "ui/components/Cart/CartUpsell/CartUpsellCard/CartUpsellCard.styles";
import { UpsellProduct } from "ui/components/Cart/CartUpsell/CartUpsellViewModel";
import Image from "ui/components/Image";
import { Size } from "ui/Size";
import { Formatter } from "util/Formatter";

type CartUpsellCardProps = {
  isAddingToCart: boolean;
  onClickAddToCart: () => void;
  onClickModify: () => void;
  product: UpsellProduct;
};

const CartUpsellCard = ({ isAddingToCart, onClickAddToCart, onClickModify, product }: CartUpsellCardProps) => {
  const primaryButton = (
    <Button
      disabled={isAddingToCart}
      onClick={onClickAddToCart}
      size="small"
      variant="outlined"
      sx={styles.primaryButton}
    >
      Add to Cart
    </Button>
  );

  const secondaryButton = (() => {
    if (ProductStore.isSideOrDrink(product)) return null;

    return (
      <Button onClick={onClickModify} size="small" variant="text">
        Modify
      </Button>
    );
  })();

  return (
    <Box component={CarouselItem} tabIndex={-1} sx={styles.slide} data-testid="upsell">
      <Card primaryButton={primaryButton} secondaryButton={secondaryButton} sx={styles.card}>
        <Stack direction="row" gap="0.75rem">
          <Image
            images={product.images ?? []}
            groupNameInfo="mobile-customize"
            imageUnavailableProps={{ size: Size.small }}
            productName={product.name ?? ""}
            sx={styles.productImage}
          />
          <Stack direction="column" justifyContent="center" sx={styles.productInfo}>
            <Typography sx={styles.productName} className="productName">
              {product.name}
            </Typography>
            <Box sx={styles.productInfoMeta}>
              {product.cost !== "$0.00" && (
                <Typography component="span" variant="bodySmallBold">{`+${product.cost}`}</Typography>
              )}
              {product.calorieInfo ? (
                <>
                  {product.cost !== "$0.00" && <Divider orientation="vertical" flexItem />}
                  <Typography component="span" variant="bodySmall">
                    {Formatter.formatCalories(product.calorieInfo)}
                  </Typography>
                </>
              ) : null}
            </Box>
          </Stack>
        </Stack>
      </Card>
    </Box>
  );
};

export default CartUpsellCard;
