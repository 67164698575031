import { StoreDataTransformable } from "dataTransformers/StoreDataTransformable";
import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { DeliveryAddress } from "models/Address";
import Coordinates from "models/Coordinates";
import { OrderType } from "models/order/OrderType";
import { CanDeliverAddress, CanDeliverResponse } from "models/store/CanDeliver";
import { Store, StoreFilter } from "models/store/Store";
import { StoreDisclaimer } from "models/store/StoreDisclaimer";
import { StoreStorable } from "stores/store/StoreStorable";

class StoreStore implements StoreStorable {
  @observable storeDisclaimers?: StoreDisclaimer[];

  private readonly storeDataTransformable: StoreDataTransformable;

  constructor(storeDataTransformable: StoreDataTransformable) {
    makeObservable(this);
    this.storeDataTransformable = storeDataTransformable;
  }

  getOrderAheadTimes = (storeId: string, orderType: OrderType) => {
    return this.storeDataTransformable.getOrderAheadTimes(storeId, orderType);
  };

  getStoresByCoordinates(coordinates: Coordinates, filters?: StoreFilter[]): Promise<Store[]> {
    return this.storeDataTransformable.getStores({ coordinates, filters });
  }

  getStoresBySearch(
    searchText?: string,
    filters?: StoreFilter[],
    canDeliver?: boolean,
    deliveryAddress?: DeliveryAddress,
    numberOfResults?: number
  ): Promise<Store[]> {
    return this.storeDataTransformable.getStores({ searchText, filters, canDeliver, deliveryAddress, numberOfResults });
  }

  @computed get menuDisclaimers() {
    return this.storeDisclaimers?.filter((disclaimer) => !disclaimer.name?.includes("hideMenu"));
  }

  @computed get productDisclaimers() {
    return this.storeDisclaimers?.filter((disclaimer) => !disclaimer.name?.includes("hideProduct"));
  }

  @action async getDisclaimers(storeId: string): Promise<void> {
    const response = await this.storeDataTransformable.getStoreDisclaimers(storeId);
    runInAction(() => {
      this.storeDisclaimers = response.storeDisclaimers;
    });
  }

  checkCanDeliver(storeId: string, address: CanDeliverAddress): Promise<CanDeliverResponse> {
    return this.storeDataTransformable.checkCanDeliver({ storeId, address });
  }
}

export default StoreStore;
