import { useAnalytics } from "analytics";
import { OrderError } from "errors/Errors";
import { Address } from "models/Address";
import { isGroupOrder } from "models/groupOrder";
import { OrderType } from "models/order/OrderType";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAlert } from "stores/alert";
import { useOrder } from "stores/order";
import { useUser } from "stores/user";
import { MainPagePath } from "ui/navigation/Pages";
import { Formatter } from "util/Formatter";

const useOrderInfoButtonViewModel = () => {
  const { addErrorAlert } = useAlert();
  const { emitAnalyticsEvent } = useAnalytics();
  const navigate = useNavigate();
  const { cancelGroupOrder, cancelOrder, order } = useOrder();
  const { isGroupOrderHost } = useUser();
  const popoverAnchorRef = useRef<HTMLElement>(null);
  const [shouldShowPopover, setShouldShowPopover] = useState(false);

  const isCurrentUserAGroupOrderHost = isGroupOrder(order) && isGroupOrderHost(order.groupOrderDetails);
  const isDelivery = order?.orderType === OrderType.delivery;
  const deliveryAddress = getDisplayAddress(order?.deliveryInfo?.address);
  const orderTypeText = isDelivery ? "Delivery to" : "Pickup from";
  const shouldShowChangeLocationText = !isGroupOrder(order);
  const storeAddress = getDisplayAddress(order?.store.address);
  const storeInfoUrl = Formatter.formatStoreInfoUrl(order?.store.address, order?.store.name);
  const storePhone = Formatter.formatPhone(order?.store.phone);

  const startOverLabel = (() => {
    if (isGroupOrder(order)) {
      return isGroupOrderHost(order.groupOrderDetails) ? "Cancel Group Order" : "Leave Group";
    }

    return "Start Over";
  })();

  const closePopover = () => setShouldShowPopover(false);

  const onClickStoreInfo = () => {
    emitAnalyticsEvent("click_link", { text: "Store Info", type: "Global Header" });
  };

  const onClickStartOver = async () => {
    closePopover();

    try {
      emitAnalyticsEvent("click_link", { text: startOverLabel, type: "Global Header" });

      if (order) {
        emitAnalyticsEvent("cancel_order", { orderId: order.id });
      }

      const orderType = order?.orderType;

      if (isCurrentUserAGroupOrderHost) {
        await cancelGroupOrder();
      } else {
        await cancelOrder();
      }

      navigate({
        pathname: MainPagePath.locations,
        search: `method=${orderType === "delivery" ? "delivery" : "pickup"}`,
      });
    } catch (e) {
      addErrorAlert(OrderError.CancelOrder, e);
    }
  };

  const togglePopover = () => {
    setShouldShowPopover((val) => !val);
  };

  return {
    deliveryAddress,
    isDelivery,
    onClickInfoButton: togglePopover,
    onClickStartOver,
    onClickStoreInfo,
    onClosePopover: closePopover,
    orderTypeText,
    popoverAnchorRef,
    shouldShowChangeLocationText,
    shouldShowPopover,
    startOverLabel,
    storeAddress,
    storeInfoUrl,
    storePhone,
  };
};

const getDisplayAddress = (address?: Address) => {
  if (!address) return;

  return {
    cityStateZip: `${address.city}${address.state ? `, ${address.state}` : ""} ${address.zipcode}`,
    crossStreet: address.crossStreet,
    streetAddress: `${address.addressLineOne}${address.addressLineTwo ? ` ${address.addressLineTwo}` : ""}`,
  };
};

export default useOrderInfoButtonViewModel;
