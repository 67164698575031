import { modifyMParticleIdentity, setMParticleIdentity } from "analytics/vendors/mParticle/mParticle.utils";
import { UserDataTransformable } from "dataTransformers/UserDataTransformable";
import { ValidationErrors } from "errors/LocalErrors";
import { isOctocartError, OctocartError } from "errors/OctocartError";
import { action, computed, makeAutoObservable, makeObservable } from "mobx";
import { GroupOrderDetails } from "models/groupOrder";
import { CreditCardDetails } from "models/payment/CreditCardPaymentRequest";
import { GiftCardDetails } from "models/payment/GiftCardPaymentRequest";
import AchievementBadge from "models/rewards/AchievementBadge";
import { RewardResponse } from "models/rewards/Reward";
import { EditUserRequestPayload, NewUserRequestPayload } from "models/User";
import { FavesRequestPayload } from "models/user/Fave";
import { SavedPayment } from "models/user/savedPayments/SavedPayment";
import { WritableLoadableObservableState } from "stores/LoadableObservableState";
import LoadableObservableStatus from "stores/LoadableObservableStatus";
import { UserStorable } from "stores/user/UserStorable";
import { logError } from "util/Logger";

class UserStore implements UserStorable {
  readonly rewardsState: WritableLoadableObservableState<RewardResponse> = {
    object: undefined,
    error: undefined,
    status: LoadableObservableStatus.Idle,
  };

  readonly savedPaymentsState: WritableLoadableObservableState<SavedPayment[]> = {
    object: undefined,
    error: undefined,
    status: LoadableObservableStatus.Idle,
  };

  private readonly userDataTransformer: UserDataTransformable;

  constructor(userDataTransformer: UserDataTransformable) {
    makeObservable(this);
    makeAutoObservable(this.rewardsState);
    makeAutoObservable(this.savedPaymentsState);
    this.userDataTransformer = userDataTransformer;
  }

  // TODO: We may want to update this getter to return:
  //   - `undefined` while auth state is undetermined,
  //   - `null` if logged out, or
  //   - `User` if logged-in (e.g. User | null | undefined).
  // This would enable us to hide any user/auth state UI while loading the user data/state.
  @computed get user() {
    return this.userDataTransformer.tokenManager.userState.object;
  }

  @computed get isLoggedIn() {
    return !!this.user;
  }

  @computed get isLoadingUser() {
    return (
      this.userDataTransformer.tokenManager.hasUserToken() &&
      this.userDataTransformer.tokenManager.userState.object === undefined &&
      this.userDataTransformer.tokenManager.userState.status !== LoadableObservableStatus.Error
    );
  }

  @computed get rewards() {
    return this.rewardsState.object;
  }

  @computed get rewardsStatus() {
    return this.rewardsState.status;
  }

  @computed get isEnrolledInRewards() {
    return !!this.userDataTransformer.tokenManager.userState.object?.enrolledInRewards;
  }

  @computed get isLoadingRewards() {
    return (
      this.userDataTransformer.tokenManager.hasUserToken() &&
      this.isEnrolledInRewards &&
      this.rewardsState.object === undefined &&
      this.rewardsState.status !== LoadableObservableStatus.Error
    );
  }

  @computed get savedPayments() {
    return this.savedPaymentsState.object;
  }

  @computed get isLoadingSavedPayments() {
    return (
      this.userDataTransformer.tokenManager.hasUserToken() &&
      this.savedPaymentsState.object === undefined &&
      this.savedPaymentsState.status !== LoadableObservableStatus.Error
    );
  }

  @action createAccount = (user: NewUserRequestPayload) => {
    this.userDataTransformer.tokenManager.userState.status = LoadableObservableStatus.Loading;

    return this.userDataTransformer
      .createAccount(user)
      .then(() => {
        return this.login(user.userName, user.password);
      })
      .catch((e) => {
        return this.handleError(e);
      });
  };

  @action deleteAccount = (userId: string) => {
    this.userDataTransformer.tokenManager.userState.status = LoadableObservableStatus.Loading;
    return this.userDataTransformer
      .deleteAccount(userId)
      .then(() => {
        this.logout();
      })
      .then(() => Promise.resolve())
      .catch((e) => this.handleError(e));
  };

  @action changeEmail(email: string, password: string) {
    if (!this.isLoggedIn || !this.user) return Promise.reject(ValidationErrors.NotLoggedIn);

    this.userDataTransformer.tokenManager.userState.status = LoadableObservableStatus.Loading;

    return this.userDataTransformer
      .changeEmail(this.user, email, password)
      .then(() => this.refreshUser())
      .catch((e) => this.handleError(e));
  }

  @action changePassword = (oldPassword: string, newPassword: string) => {
    if (!this.isLoggedIn || !this.user) return Promise.reject(ValidationErrors.NotLoggedIn);

    this.userDataTransformer.tokenManager.userState.status = LoadableObservableStatus.Loading;

    const userName = this.user.userName;

    return this.userDataTransformer
      .changePassword(this.user, oldPassword, newPassword)
      .then(() => this.login(userName, newPassword))
      .then(() => this.refreshUser())
      .catch((e) => this.handleError(e));
  };

  @action deletePayment = async (savedPayment: SavedPayment) => {
    try {
      if (!this.isLoggedIn || !this.user) throw ValidationErrors.NotLoggedIn;

      this.savedPaymentsState.status = LoadableObservableStatus.Loading;

      const payments = await this.userDataTransformer.deleteSavedPayment(this.user, savedPayment);
      this.updateSavedPaymentState(payments);
    } catch (e) {
      return this.handleError(e);
    }
  };

  @action editAccount = (userFields: Partial<EditUserRequestPayload>) => {
    if (!this.isLoggedIn || !this.user) return Promise.reject(ValidationErrors.NotLoggedIn);

    this.userDataTransformer.tokenManager.userState.status = LoadableObservableStatus.Loading;

    const editAccountFields = {
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      email: this.user.userName,
      phoneNumber: this.user.phoneNumber,
      ...userFields,
    };

    return this.userDataTransformer
      .editAccount(this.user, editAccountFields)
      .then((user) => this.userDataTransformer.tokenManager.updateUserState(user))
      .then(() => this.refreshRewards())
      .then(() => modifyMParticleIdentity({ email: editAccountFields.email, phone: editAccountFields.phoneNumber }))
      .catch((e) => this.handleError(e));
  };

  @action editPaymentDisplayName(savedPayment: SavedPayment, displayName: string) {
    if (!this.isLoggedIn || !this.user) return Promise.reject(ValidationErrors.NotLoggedIn);

    this.savedPaymentsState.status = LoadableObservableStatus.Loading;

    return this.userDataTransformer
      .editPayment(this.user, savedPayment, displayName)
      .then((payments) => this.updateSavedPaymentState(payments))
      .catch((e) => this.handleError(e));
  }

  sendForgotPasswordEmail = async (email: string) => {
    try {
      return await this.userDataTransformer.sendForgotPasswordEmail(email);
    } catch (e) {
      return this.handleError(e);
    }
  };

  @action refreshRewards = () => {
    if (!this.user?.enrolledInRewards) return Promise.resolve();

    this.rewardsState.status = LoadableObservableStatus.Loading;

    return this.userDataTransformer
      .getRewards()
      .then((rewards) => this.updateRewardsState(rewards))
      .catch((e) => {
        this.handleError(e);
        this.updateRewardsState(undefined, e);
      });
  };

  @action updateAchievementBadgesAsSeen = async (badges: AchievementBadge[]) => {
    try {
      const payload = badges.map(({ perkCode, status }) => {
        let newStatus: "ACHIEVED_AND_SEEN" | "AVAILABLE_AND_SEEN";

        switch (status) {
          case "ACHIEVED":
            newStatus = "ACHIEVED_AND_SEEN";
            break;
          case "AVAILABLE":
            newStatus = "AVAILABLE_AND_SEEN";
            break;
          default:
            throw new Error(`Cannot update badge as seen with status "${status}"`);
        }

        return {
          badgeStatus: newStatus,
          perkCode,
        };
      });

      const rewards = await this.userDataTransformer.updateAchievementBadges(payload);

      this.updateRewardsState(rewards);
    } catch (e) {
      logError(e);
    }
  };

  @action async login(username: string, password: string) {
    this.userDataTransformer.tokenManager.userState.status = LoadableObservableStatus.Loading;

    try {
      const user = await this.userDataTransformer.login(username, password);
      this.userDataTransformer.tokenManager.updateUserState(user);
    } catch (e) {
      this.userDataTransformer.tokenManager.clearUserState();

      return this.handleError(e);
    }
  }

  async logout() {
    try {
      await this.userDataTransformer.logout();
    } catch (e) {
      return this.handleError(e);
    }
  }

  @action refreshSavedPayments = () => {
    if (!this.isLoggedIn || !this.user) return Promise.reject(ValidationErrors.NotLoggedIn);

    this.savedPaymentsState.status = LoadableObservableStatus.Loading;

    return this.userDataTransformer
      .getSavedPayments(this.user)
      .then((payments) => this.updateSavedPaymentState(payments))
      .catch((e) => {
        this.handleError(e);
        this.updateSavedPaymentState(undefined, e);
      });
  };

  @action refreshUser(shouldFetchCurrentUser = true) {
    if (!this.userDataTransformer.tokenManager.hasUserToken()) {
      this.userDataTransformer.tokenManager.clearUserState();
      this.clearUserState();
      return Promise.resolve();
    }

    this.userDataTransformer.tokenManager.userState.status = LoadableObservableStatus.Loading;
    this.rewardsState.status = LoadableObservableStatus.Loading;
    this.savedPaymentsState.status = LoadableObservableStatus.Loading;

    if (shouldFetchCurrentUser) {
      return this.userDataTransformer
        .getCurrentUser()
        .then((user) => {
          this.userDataTransformer.tokenManager.updateUserState(user);
          const refreshSavedPaymentsPromise = this.refreshSavedPayments().catch(() => {}); // silence errors
          const refreshRewardsPromise = this.refreshRewards().catch(() => {}); // silence errors
          return Promise.allSettled([refreshSavedPaymentsPromise, refreshRewardsPromise])
            .then(() => {
              if (!this.user) return;
              setMParticleIdentity({
                userId: this.user.userGuid,
                email: this.user.email,
                phone: this.user.phoneNumber,
                loyaltyCardNumber: this.rewards?.membershipNumber,
              });
            })
            .then(() => Promise.resolve());
        })
        .catch((e) => this.handleError(e));
    } else {
      const refreshSavedPaymentsPromise = this.refreshSavedPayments().catch(() => {}); // silence errors
      const refreshRewardsPromise = this.refreshRewards().catch(() => {}); // silence errors
      return Promise.allSettled([refreshSavedPaymentsPromise, refreshRewardsPromise])
        .then(() => {
          if (!this.user) return;
          setMParticleIdentity({
            userId: this.user.userGuid,
            email: this.user.email,
            phone: this.user.phoneNumber,
            loyaltyCardNumber: this.rewards?.membershipNumber,
          });
        })
        .then(() => Promise.resolve())
        .catch((e) => this.handleError(e));
    }
  }

  @action saveCreditCard(creditCard: CreditCardDetails, setAsDefault: boolean, displayName?: string) {
    if (!this.isLoggedIn || !this.user) return Promise.reject(ValidationErrors.NotLoggedIn);

    this.savedPaymentsState.status = LoadableObservableStatus.Loading;

    return this.userDataTransformer
      .saveCreditCard(this.user, creditCard, displayName, setAsDefault)
      .then((payments) => this.updateSavedPaymentState(payments))
      .catch((e) => this.handleError(e));
  }

  @action saveGiftCard(giftCard: GiftCardDetails, setAsDefault: boolean, displayName?: string) {
    if (!this.isLoggedIn || !this.user) return Promise.reject(ValidationErrors.NotLoggedIn);

    this.savedPaymentsState.status = LoadableObservableStatus.Loading;

    return this.userDataTransformer
      .saveGiftCard(this.user, giftCard, displayName, setAsDefault)
      .then((payments) => this.updateSavedPaymentState(payments))
      .catch((e) => this.handleError(e));
  }

  getDeliveryAddresses = async () => {
    try {
      return await this.userDataTransformer.getDeliveryAddresses();
    } catch (e) {
      return this.handleError(e);
    }
  };

  removeDeliveryAddress = async (addressId: string) => {
    try {
      return await this.userDataTransformer.removeDeliveryAddress(addressId);
    } catch (e) {
      return this.handleError(e);
    }
  };

  getAllowedSavedPayments = async () => {
    try {
      return await this.userDataTransformer.getAllowedSavedPayments();
    } catch (e) {
      return this.handleError(e);
    }
  };

  updateDefaultDeliveryAddress = async (addressId: string) => {
    try {
      return await this.userDataTransformer.updateDefaultDeliveryAddress(addressId);
    } catch (e) {
      return this.handleError(e);
    }
  };

  updateDefaultSavedLocation = async (storeId: string) => {
    try {
      return await this.userDataTransformer.createSavedLocation(storeId, true);
    } catch (e) {
      return this.handleError(e);
    }
  };

  getRecentOrders = async () => {
    try {
      const recentOrders = await this.userDataTransformer.getRecentOrders();

      // recent orders that contain 0 products should be filtered out
      // (i.e. orders that contained only products that are no longer available; aka LTOs)
      return recentOrders.filter(({ products }) => products.length > 0);
    } catch (e) {
      return this.handleError(e);
    }
  };

  getRecentOrderDetails = async (recentOrderId: string) => {
    try {
      return await this.userDataTransformer.getRecentOrderDetails(recentOrderId);
    } catch (e) {
      return this.handleError(e);
    }
  };

  getSavedGroupOrderParticipants = async () => {
    try {
      return await this.userDataTransformer.getSavedGroupOrderParticipants();
    } catch (e) {
      return this.handleError(e);
    }
  };

  getSavedLocations = async () => {
    try {
      return await this.userDataTransformer.getSavedLocations();
    } catch (e) {
      return this.handleError(e);
    }
  };

  removeSavedLocation = async (storeId: string) => {
    try {
      return await this.userDataTransformer.removeSavedLocation(storeId);
    } catch (e) {
      return this.handleError(e);
    }
  };

  getFaves = async () => {
    try {
      return await this.userDataTransformer.getFaves();
    } catch (e) {
      return this.handleError(e);
    }
  };

  createFave = async (payload: FavesRequestPayload) => {
    try {
      if (!this.isLoggedIn || !this.user) throw ValidationErrors.NotLoggedIn;

      this.savedPaymentsState.status = LoadableObservableStatus.Loading;

      return await this.userDataTransformer.createFave(payload);
    } catch (e) {
      return this.handleError(e);
    }
  };

  deleteFave = async (faveId: string) => {
    try {
      if (!this.isLoggedIn || !this.user) throw ValidationErrors.NotLoggedIn;
      return await this.userDataTransformer.deleteFave(faveId);
    } catch (e) {
      return this.handleError(e);
    }
  };

  @action setDefaultPayment = async (savedPayment: SavedPayment) => {
    try {
      if (!this.isLoggedIn || !this.user) throw ValidationErrors.NotLoggedIn;

      this.savedPaymentsState.status = LoadableObservableStatus.Loading;

      const payments = await this.userDataTransformer.editPayment(this.user, savedPayment, undefined, true);
      this.updateSavedPaymentState(payments);
    } catch (e) {
      return this.handleError(e);
    }
  };

  @action handleError(error: unknown) {
    this.userDataTransformer.tokenManager.userState.status = LoadableObservableStatus.Error;

    if (isOctocartError(error)) {
      this.userDataTransformer.tokenManager.userState.error = error;
    }

    return Promise.reject(error);
  }

  @action private updateRewardsState(rewards: RewardResponse | undefined, error?: OctocartError) {
    this.rewardsState.object = rewards;
    this.rewardsState.error = error;
    this.rewardsState.status = error ? LoadableObservableStatus.Error : LoadableObservableStatus.Idle;
  }

  @action private updateSavedPaymentState(payments: SavedPayment[] | undefined, error?: OctocartError) {
    this.savedPaymentsState.object = payments;
    this.savedPaymentsState.error = error;
    this.savedPaymentsState.status = error ? LoadableObservableStatus.Error : LoadableObservableStatus.Idle;
  }

  @action private clearUserState() {
    this.rewardsState.object = undefined;
    this.rewardsState.error = undefined;
    this.rewardsState.status = LoadableObservableStatus.Idle;

    this.savedPaymentsState.object = undefined;
    this.savedPaymentsState.error = undefined;
    this.savedPaymentsState.status = LoadableObservableStatus.Idle;
  }

  isGroupOrderHost = (groupOrderDetails: GroupOrderDetails) => {
    if (!this.user) return false;

    const host = groupOrderDetails.participants.find((participant) => participant.id === "host");

    if (!host) return false;

    return this.user.email === host.email;
  };
}

export default UserStore;
