import "ui/App.css";
import mParticle from "@mparticle/web-sdk";
import { ThemeProvider } from "@mui/material";
import { OptimizelyProvider } from "@optimizely/react-sdk";
import AnalyticsProvider from "analytics";
import { initMParticle } from "analytics/vendors/mParticle/mParticle.utils";
import { getOptimizelyClient, getOptimizelyUser } from "analytics/vendors/Optimizely/Optimizely.utils";
import branch from "branch-sdk";
import { lazy, Suspense, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { AlertProvider } from "stores/alert";
import { CartProvider } from "stores/cart";
import { GlobalModalPresenter, GlobalModalProvider } from "stores/globalModal";
import { MenuProvider } from "stores/menu";
import { OrderProvider } from "stores/order";
import { StoreProvider } from "stores/store";
import StoreManagerProvider from "stores/StoreManagerProvider";
import { UserProvider } from "stores/user";
import Layout from "ui/app/Layout";
import { AppSize } from "ui/AppSize";
import ErrorBoundary from "ui/components/ErrorBoundary";
import SomethingWentWrong from "ui/components/ErrorBoundary/SomethingWentWrong";
import Spinner from "ui/components/Spinner";
import GlobalStyle from "ui/GlobalStyle";
import { MainPagePath } from "ui/navigation/Pages";
import AccountRoutes, { MyFavesRoutes } from "ui/navigation/routes/AccountRoutes";
import AuthenticationRoutes from "ui/navigation/routes/AuthenticationRoutes";
import CheckoutRoutes from "ui/navigation/routes/CheckoutRoutes";
import GroupOrderRoutes from "ui/navigation/routes/GroupOrderRoutes";
import InfoRoutes from "ui/navigation/routes/InfoRoutes";
import MenuRoutes from "ui/navigation/routes/MenuRoutes";
import muiTheme from "ui/theme/muiTheme";
import ScrollToTop from "ui/util/ScrollToTop";

const DocumentRoute = lazy(() => import("ui/navigation/routes/DocumentRoute"));
const HomeScreenView = lazy(() => import("ui/screens/HomeScreen"));
const LocationsScreen = lazy(() => import("ui/screens/LocationsScreen"));
const PageNotFound = lazy(() => import("ui/components/Error/PageNotFound"));

const App = () => {
  // init user tracking
  useEffect(() => {
    initMParticle();
    branch.init(Env.BranchApiKey ?? "");

    // set tracking opt in/out state based on Ketch analytics consent preferences
    ketch("on", "consent", (consent: Ketch.Consent) => {
      const hasUserOptedOutOfTracking =
        typeof consent?.purposes?.behavioral_advertising === "boolean" &&
        consent.purposes.behavioral_advertising === false;

      mParticle.setOptOut(hasUserOptedOutOfTracking);
      branch.disableTracking(hasUserOptedOutOfTracking);
    });

    // set focus on the "enable accessibility" button when the privacy modal is closed (for screen readers)
    ketch("on", "hideExperience", (reason: string) => {
      if (reason === "willNotShow") return;

      const enableAccessibilityButton = document.getElementById("usntA40Link");
      enableAccessibilityButton?.focus();
    });
  }, []);
  // bootstrap AppSize
  useEffect(() => {
    AppSize.start(window, document);
  }, []);

  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={muiTheme}>
        <ErrorBoundary>
          <Suspense fallback={<Spinner />}>
            <OptimizelyProvider optimizely={getOptimizelyClient()} user={getOptimizelyUser()} timeout={500}>
              <CartProvider>
                <OrderProvider>
                  <StoreProvider>
                    <UserProvider>
                      <MenuProvider>
                        <AnalyticsProvider>
                          <GlobalModalProvider>
                            <AlertProvider>
                              <StoreManagerProvider>
                                <BrowserRouter>
                                  <ScrollToTop />
                                  <Routes>
                                    <Route element={<Layout />}>
                                      <Route index element={<HomeScreenView />} />
                                      {AccountRoutes}
                                      {CheckoutRoutes}
                                      {MenuRoutes}
                                      {InfoRoutes}
                                      {MyFavesRoutes}
                                    </Route>
                                    <Route element={<Layout fullWidth />}>
                                      <Route path={MainPagePath.locations} element={<LocationsScreen />} />
                                    </Route>
                                    <Route path={MainPagePath.documents} element={<DocumentRoute />} />
                                    {AuthenticationRoutes}
                                    {GroupOrderRoutes}
                                    {/* Error routes */}
                                    <Route element={<Layout />}>
                                      <Route path={MainPagePath.error} element={<SomethingWentWrong />} />
                                      <Route path={MainPagePath.notFound} element={<PageNotFound />} />
                                      <Route path="*" element={<Navigate to={MainPagePath.home} replace />} />
                                    </Route>
                                  </Routes>
                                  <GlobalModalPresenter />
                                </BrowserRouter>
                              </StoreManagerProvider>
                            </AlertProvider>
                          </GlobalModalProvider>
                        </AnalyticsProvider>
                      </MenuProvider>
                    </UserProvider>
                  </StoreProvider>
                </OrderProvider>
              </CartProvider>
            </OptimizelyProvider>
          </Suspense>
        </ErrorBoundary>
      </ThemeProvider>
    </>
  );
};

export default App;
